import React from 'react'
import GuestBar from '../../components/Nav/GuestBar'
import Heading from '../../components/Text/Heading'
import bg from '../../assets/bg.png'
import HeroHeading from '../../components/Text/HeroHeading'
import Subheading from '../../components/Text/Subheading'
import Card from '../../components/Cards/Card'
import boxLogo from '../../assets/integrations/box-logo.png'
import docsLogo from '../../assets/integrations/docs-logo.png'
import youtubeLogo from '../../assets/integrations/youtube-logo.png'
import loomLogo from '../../assets/integrations/loom-logo.png'
import figmaLogo from '../../assets/integrations/figma-logo.png'
import driveLogo from '../../assets/integrations/drive-logo.webp'
import Text from '../../components/Text/Text'
import chromeLogo from '../../assets/integrations/chrome-logo.png'
import {useNavigate} from 'react-router-dom'
import useSEO from '../../hooks/useSEO'
import usePageTitle from '../../hooks/usePageTitle'


export default function Integrations() {
    usePageTitle('Flowsage Integrations')
    const navigate=useNavigate()

    return (
        <div style={{backgroundImage: `url(${bg})`}} className='min-h-full overflow-scroll bg-no-repeat bg-contain'>
            <GuestBar />
            <div className={`h-full p-3 lg:p-16 lg: pt-0`}>
                <div className="flex flex-col justify-center w-full p-4 px-0 pt-5 mx-auto lg:py-20 lg:px-10 align-center">
                    <HeroHeading className='lg:!font-bold !font-semibold text-center lg:block'>Flowsage Integrations</HeroHeading>
                    <Subheading className="w-full p-2 pl-0 mx-auto mt-5 text-center lg:w-2/3 lg:text-lg text-dark-200 text-md">
                        Supercharge your productivity with your favorite apps on Flowsage
                    </Subheading>
                </div>
                <div className='grid w-full grid-cols-1 gap-6 lg:grid-cols-4 place-items-center'>
                    <Card clickable onClick={() => navigate('/dashboard')} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src={boxLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>Box</Subheading>
                        <Text className='text-left'>Embed Box files in your designs</Text>
                    </Card>
                    <Card clickable onClick={() => navigate('/dashboard')} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src={docsLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>Google Docs</Subheading>
                        <Text className='text-left'>Embed Google Docs in your designs</Text>
                    </Card>
                    <Card clickable onClick={() => navigate('/dashboard')} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src={youtubeLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>YouTube</Subheading>
                        <Text className='text-left'>Embed YouTube videos in your designs</Text>
                    </Card>
                    <Card clickable onClick={() => navigate('/dashboard')} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src
                            ={loomLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>Loom</Subheading>
                        <Text className='text-left'>Embed Loom videos in your designs</Text>
                    </Card>
                    <Card clickable onClick={() => navigate('/dashboard')} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src
                            ={figmaLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>Figma</Subheading>
                        <Text className='text-left'>Embed Figma files in your designs</Text>
                    </Card>
                    <Card clickable onClick={() => navigate('/dashboard')} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src
                            ={driveLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>Google Drive</Subheading>
                        <Text className='text-left'>Embed Google Drive files in your designs</Text>
                    </Card>
                    <Card clickable onClick={() => window.location.origin='https://chromewebstore.google.com/detail/flowsage-extension-turn-i/cmbiajdlgmlogobjoihkmjkbmmkfoboa?authuser=0&hl=en'} className='w-3/4 p-4 lg:min-w-full'>
                        <img className='object-contain w-1/4 p-2 pl-0 aspect-square' src
                            ={chromeLogo} alt="Box" />
                        <Subheading className='mt-6 text-center'>Chrome</Subheading>
                        <Text className='text-left'>Turn any text on the web into a shareable flowchart.</Text>
                    </Card>
                </div>
            </div>
        </div>
    )
}

/* global chrome */
import React,{useEffect,useState,useContext} from 'react'
import {getStructuredGeneration} from '../../helpers/functions'
import {useNavigate} from 'react-router-dom'
import Loading from '../Loading'
import ErrorContext from '../../contexts/ErrorContext'
import {logEvent} from 'firebase/analytics'
import analytics from '../../helpers/analytics'

export default function GenerateFromExtension() {
    const navigate=useNavigate()
    const setError=useContext(ErrorContext)

    const [textValue,setTextValue]=useState('')
    const [loading,setLoading]=useState(false)

    useEffect(() => {
        chrome.runtime.sendMessage('cmbiajdlgmlogobjoihkmjkbmmkfoboa',{action: 'getText'},function(response) {
            setTextValue(response)
            console.log(response)
        })
    },[])

    useEffect(() => {
        if(textValue&&!loading) {
            setLoading(true)
            console.log(textValue)
            getStructuredGeneration({prompt: textValue})
                .then((result) => {
                    console.log(result.data)
                    navigate('/dashboard/design/'+result.data)
                    setLoading(false)
                }).catch((error) => {
                    if(error.code==='functions/resource-exhausted') {
                        if(error.details==='You have reached the maximum number of projects allowed for the free plan') {
                            setError('Sorry, it looks like you ran out of projects. Please upgrade your plan to continue using this feature.')
                            logEvent(analytics,'completion_resource_exhausted')
                            setLoading(false)
                            navigate('/pricing')
                            return
                        }
                        setError('Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.')
                        logEvent(analytics,'completion_resource_exhausted')
                        setLoading(false)
                        navigate('/pricing')
                        return
                    }
                })
        }
    },[textValue,loading,navigate,setError])


    return (
        <Loading />
    )
}

import classNames from 'classnames'
import React,{useContext,useEffect} from 'react'
import {ConnectionMode,Handle,Position} from 'reactflow'
import {NodeResizer} from 'reactflow'
import UserContext from '../../contexts/UserContext'
import useColors from '../../hooks/useColors'
import NodeContext from '../../contexts/NodeContext'

// Node should have handles on all four sides
export default function Node({children,nodeId,nodePositions=[1,1,1,1],resizable=true,deactivated=false,selected=false,approved=true,keepAspectRatio=true,className,data,...props}) {

    const [user]=useContext(UserContext)
    const [setNodeValue,getNodeValue,deleteNode]=useContext(NodeContext)


    const {getColorOfUser}=useColors()



    return (
        <>
            <NodeResizer keepAspectRatio={keepAspectRatio} color={getColorOfUser(data?.selectedBy?.uid||'',user?.uid||'')} isVisible={(selected&&resizable&&approved&&user?.uid)||(!!data?.selectedBy&&data?.selectedBy!==user?.uid)} minWidth={140} />
            <div className={classNames((deactivated||!approved)&&'opacity-30 relative',className)} {...props}>
                {children||'Node'}
                {(user?.uid&&(data?.selectedBy&&data?.selectedBy?.uid!==user?.uid))&&<div className={'absolute left-0 text-[0.4rem] -bottom-4'} style={{color: getColorOfUser(data?.selectedBy?.uid||'',user?.uid)}}>{data.selectedBy?.displayName} editing</div>}
            </div>
            {!!nodePositions[0]&&<Handle id={nodeId+'a'} isConnectable={!deactivated&&approved} position={Position.Top} className={classNames((selected&&approved&&!deactivated)? 'opacity-75':'opacity-0')} type='source'></Handle>}
            {!!nodePositions[1]&&<Handle isConnectable={!deactivated&&approved} id={nodeId+'b'} position={Position.Right} className={classNames((selected&&approved&&!deactivated)? 'opacity-75':'opacity-0')} type='source'></Handle>}
            {!!nodePositions[2]&&<Handle id={nodeId+'c'} isConnectable={!deactivated&&approved} position={Position.Bottom} className={classNames((selected&&approved&&!deactivated)? 'opacity-75':'opacity-0')} type='source'></Handle>}
            {!!nodePositions[3]&&<Handle id={nodeId+'d'} isConnectable={!deactivated&&approved} position={Position.Left} className={classNames((selected&&approved&&!deactivated)? 'opacity-75':'opacity-0')} type='source'></Handle>}
        </>
    )
}

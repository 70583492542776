/** @format */

import React,{useContext,useEffect,useState} from 'react'
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary'
import {deleteUser,getAuth,reauthenticateWithRedirect,signOut,GoogleAuthProvider,signInWithRedirect,ProviderId,reauthenticateWithCredential,EmailAuthCredential,EmailAuthProvider,sendSignInLinkToEmail,updateCurrentUser,updateProfile} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import Subheading from '../../../components/Text/Subheading'
import ButtonSecondary from '../../../components/Buttons/ButtonSecondary'
import ButtonDanger from '../../../components/Buttons/ButtonDanger'
import Text from '../../../components/Text/Text'
import {getTemperature,updateTemperature,createPortalLink} from '../../../helpers/functions'
import Link from '../../../components/Links/Link'
import HorizontalDivider from '../../../components/Dividers/HorizontalDivider'
import useUserSubscription from '../../../hooks/useUserSubscription'
import usePageTitle from '../../../hooks/usePageTitle'
import {doc,getDoc} from 'firebase/firestore'
import {db} from '../../../helpers/firebase'
import analytics from '../../../helpers/analytics'
import UserContext from '../../../contexts/UserContext'
import ErrorContext from '../../../contexts/ErrorContext'
import {logEvent} from 'firebase/analytics'
import {storage} from '../../../helpers/firebase'
import {getStorage,getDownloadURL,ref,uploadBytes} from 'firebase/storage'
import Icon from '../../../components/Text/Icon'
import Credits from '../../../components/Progress/Credits'
import CreditsContext from '../../../contexts/CreditsContext'
import {getAllByTestId} from '@testing-library/react'

export default function Settings() {
    const [pageTitle,setPageTitle]=usePageTitle('Settings')

    const [user]=useContext(UserContext)
    const [credits,updateCredits]=useContext(CreditsContext)

    const setError=useContext(ErrorContext)

    const [userPlan,setUserPlan]=useState('free')
    const [isImageLoading,setIsImageLoading]=useState(false)

    let navigate=useNavigate()
    const {getUserPlan}=useUserSubscription()
    const auth=getAuth()

    function handleSignout(event) {
        event.preventDefault()
        signOut(auth)
            .then(() => {
                logEvent(analytics,'sign_out')
                navigate('/login')
            })
            .catch((err) => {
                setError('Something went wrong signing you out. Please try again.')
            })
    }

    function handleSubscription() {
        if(userPlan==='plus'||userPlan==='premium') {
            createPortalLink({
                returnUrl: window.location.origin,
                locale: "auto",
            }).then((data) => {
                logEvent(analytics,'portal_link_created')
                window.location.assign(data.data.url);
            })
        } else {
            logEvent(analytics,'redirect-to-pricing',{
                redirectFrom: 'settings'
            })
            navigate('/pricing')
        }
    }

    useEffect(() => {
        getUserPlan().then((plan) => {
            setUserPlan(plan)
        })
        updateCredits()
    },[user.uid,updateCredits,setUserPlan,getUserPlan])

    const handleUploadImage=async (event) => {
        if(event.target.files.length===0) {
            return
        }
        if(event.target.files[0].size>1000000) {
            setError('Image must be less than 1MB')
            return
        }
        if(!event.target.files[0].type.startsWith('image/')) {
            setError('File must be an image')
            return
        }
        if(event.target.files[0].type==='image/gif') {
            setError('GIFs are not supported')
            return
        }
        if(event.target.files[0].type==='image/svg+xml') {
            setError('SVGs are not supported')
            return
        }
        if(event.target.files[0].type==='image/webp') {
            setError('WebP images are not supported')
            return
        }
        if(event.target.files[0].type==='image/tiff') {
            setError('TIFF images are not supported')
            return
        }
        if(event.target.files[0].type==='image/bmp') {
            setError('BMP images are not supported')
            return
        }
        setIsImageLoading(true)
        const imageRef=ref(storage,`users/${user.uid}/profile.`+event.target.files[0].type.split('/')[1])
        const file=event.target.files[0]
        await uploadBytes(imageRef,file)
        const url=await getDownloadURL(imageRef)
        logEvent(analytics,'profile_image_changed')
        updateProfile(user,{
            photoURL: url
        }).then(() => {
            setIsImageLoading(false)
        })
    }

    function handleDelete(event) {
        event.preventDefault()
        logEvent(analytics,'delete_account')
        navigate(`/delete/${auth.currentUser.uid}`)
    }

    return (<>
        <Subheading className="w-full pb-1 !text-3xl pt-4 lg:px-6 px-4">
            Settings
        </Subheading>
        <div className="flex flex-col items-start justify-start w-full h-full p-2 px-6 m-0 mx-auto mt-8 lg:flex-row">
            <div className='flex items-center justify-center w-full mx-auto lg:w-1/5'>
                <div className='relative flex flex-col items-center justify-center w-64 p-2 mx-auto'>
                    <img src={user.photoURL} className='object-cover w-64 h-64 shadow-md rounded-3xl ms-clarity-hidden' alt='Profile' />
                    <label htmlFor='upload-photo' className='absolute flex items-center justify-center p-2 px-2 text-2xl rounded-full shadow-md cursor-pointer -right-3 aspect-square bg-light bottom-4 '><Icon iconClassName='pb-1' icon='pencil' /></label>
                    <input accept='.png,.jpg,.jpeg' type='file' id="upload-photo" disabled={isImageLoading} onChange={handleUploadImage} className='w-64 h-0 p-2 mt-1 rounded-md opacity-0 pointer-events-none' />
                </div>
            </div>
            <div className='flex flex-col items-start justify-start p-2 lg:w-4/5'>
                <div className="box-border flex flex-col items-start justify-start w-full h-full p-2 m-0 !mx-auto  lg:px-20" >
                    <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
                        <Subheading className='my-auto font-normal'><span className='font-bold ms-clarity-hidden'>{user.displayName||user.email}</span> ({user.displayName&&user.email})</Subheading>
                    </div>
                    <HorizontalDivider />
                    <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
                        <Subheading className='my-auto'>{(userPlan==='plus'||userPlan==='premium')? 'Manage your subscription':'Upgrade your account'}</Subheading>
                        <ButtonPrimary onClick={handleSubscription} className='w-full lg:w-64'>{(userPlan==='plus'||userPlan==='premium')? 'Payment Dashboard':'View Pricing'}</ButtonPrimary>
                    </div>
                    {userPlan!=='premium'&&<>
                        <HorizontalDivider />
                        <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
                            <Subheading className='my-auto'>Your Flowsage AI Credits</Subheading>
                            <Credits plan={userPlan} credits={credits} />
                        </div></>}
                    <HorizontalDivider />
                    <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
                        <div>
                            <Subheading className="pb-0">
                                Your Account
                            </Subheading>
                            <Text>Warning: Deleting your account is an irreversible action.</Text>
                        </div>
                        <div className='flex flex-col items-center justify-center w-full gap-2 lg:w-auto lg:gap-2 lg:flex-row'>
                            <ButtonSecondary className='!w-full lg:!w-64' onClick={handleSignout}>Sign out</ButtonSecondary>
                            <ButtonDanger onClick={handleDelete} className='!w-full lg:!w-64'>Delete Account</ButtonDanger>
                        </div>
                    </div>
                    <HorizontalDivider />
                    <div className='my-5'>
                        <Text className='font-semibold'>If you have any questions, email us at <Link to='mailto:team@flowsage.co'>team@flowsage.co</Link></Text>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

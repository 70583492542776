import React,{useContext} from 'react'
import ReactMarkdown from 'react-markdown'
import Icon from '../Text/Icon'
import Text from '../Text/Text'
import {useAuthState} from 'react-firebase-hooks/auth'
import UserContext from '../../contexts/UserContext'
import classNames from 'classnames'

export default function Bubble({chat,index,deleteChatBubble,label,...props}) {
    const [user,loading,error]=useContext(UserContext)

    return (
        <div className={classNames('block w-full mb-2 h-fit',(label&&chat.user===user.displayName)&&'!mb-4')}>
            <div key={index} className={`flex items-center justify-between w-3/4 flex-shrink-0 gap-2 px-2 py-2 rounded-lg ${(chat.role==='model'||chat.user!==user.displayName)? ' ml-2 bg-primary':'bg-secondary-600 ml-auto'}`}>
                <Text className='w-full font-medium text-left text-white whitespace-pre-wrap lg:text-md'><ReactMarkdown>{`${chat.content.replace(/(?:\r\n|\r|\n)/g,'  \n')}`}</ReactMarkdown></Text>
            </div>
            {label&&chat.user!==user.displayName&&<Text className='ml-2 text-xs text-left text-zinc-700'>{chat.user||'AI Assistant'}</Text>
            }
        </div>
    )
}

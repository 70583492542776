/** @format */

import classnames from 'classnames'
import React,{useContext} from 'react'
import Text from '../Text/Text'
import {getAuth,signInWithRedirect,GoogleAuthProvider,setPersistence,browserLocalPersistence,signInWithPopup} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import ErrorContext from '../../contexts/ErrorContext'
import Icon from '../Text/Icon'
import {logEvent} from 'firebase/analytics'
import analytics from '../../helpers/analytics'
import googleIcon from '../../assets/google.svg'

export default function SignInWithGoogle({children,redirect='',...props}) {
  let navigate=useNavigate()
  const setError=useContext(ErrorContext)
  const provider=new GoogleAuthProvider()

  const auth=getAuth()
  function handleClick() {
    setPersistence(auth,browserLocalPersistence).then(async () => {
      const result=await signInWithPopup(auth,provider)
      if(result) {
        const isNewUser=result.user.metadata.creationTime===result.user.metadata.lastSignInTime
        navigate(decodeURIComponent(redirect)||(isNewUser? '/pricing':'/dashboard'))
        logEvent(analytics,'signInWithGoogle')
      } else {
        setError('Something went wrong, please try again!')
      }
    }).catch(() => setError('Something went wrong, please try again!'))
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={classnames(
          'w-80 lg:w-128 py-3 m-0 bg-light border border-secondary rounded-full text-secondary font-semibold shadow-md hover:shadow-lg transition-all hover:bg-dark-500 grid grid-cols-4 justify-center items-center mt-2',
          props.className
        )}>
        <img className='inline-block pl-4 max-h-8 max-w-8' src={googleIcon} alt='Google' />
        <Text className='inline-block text-center col-span-2 w-full !text-secondary p-0'>Sign in with Google</Text>
      </button>
    </>
  )
}

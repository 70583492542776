import React from 'react'
import Icon from '../../components/Text/Icon'
import Text from '../../components/Text/Text'
import Subheading from '../../components/Text/Subheading'
import Link from '../../components/Links/Link'
import usePageTitle from '../../hooks/usePageTitle'
import useSEO from '../../hooks/useSEO'

export default function Error() {
    const [pageTitle,setPageTitle]=usePageTitle('Unexpected Error')
    useSEO({indexable: false})

    return (
        <div className='flex flex-col items-center justify-center w-full h-screen'>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5'>
                <Icon className='m-0 text-5xl text-primary-200 lg:text-9xl animate-spin ' icon='bounding-box-circles' />
                <Subheading>Something went wrong!</Subheading>
                <Text className='w-2/3 px-4 text-center lg:w-1/2 xl:w-1/6'>We're sorry this happened. Please try again. </Text>
            </div>
        </div>
    )
}

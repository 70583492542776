/** @format */

import React,{useContext,useEffect,useState} from 'react'
import SignInWithGoogle from '../../components/Buttons/SignInWithGoogle'
import Input from '../../components/Inputs/Input'
import Text from '../../components/Text/Text'
import Logobar from '../../components/Nav/Logobar'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import {getAuth,sendSignInLinkToEmail} from 'firebase/auth'
import usePageTitle from '../../hooks/usePageTitle'
import ErrorContext from '../../contexts/ErrorContext'
import useSEO from '../../hooks/useSEO'
import useValidator from '../../hooks/useValidator'
import {logEvent} from 'firebase/analytics'
import analytics from '../../helpers/analytics'
import Subheading from '../../components/Text/Subheading'
import {useParams} from 'react-router-dom'
import {useEnv} from '../../hooks/useEnv'

export default function Login() {
    usePageTitle('Login')
    useSEO({indexable: false})

    const validator=useValidator()

    const setError=useContext(ErrorContext)

    const {redirect}=useParams()

    const [loginEmail,setLoginEmail]=useState('')
    const [loginEmailSent,setLoginEmailSent]=useState(false)
    const [loginWithEmailError,setLoginWithEmailError]=useState(false)
    const [loginWithEmailSuccess,setLoginWithEmailSuccess]=useState(false)
    const [loginWithEmailInstructions,setLoginWithEmailInstructions]=useState([
        'By logging in, you agree to our Terms of Service and Privacy Policy.',
        'dark',
    ])
    const {getBaseUrl}=useEnv()

    const actionCodeSettings={
        url: `${getBaseUrl()}/verify/`+(redirect||''),
        handleCodeInApp: true,
    }

    useEffect(() => {
        if(loginWithEmailSuccess) {
            setLoginWithEmailInstructions([
                'A link has been sent to your email.',
                'primary',
            ])
            return
        }
        if(loginWithEmailError) {
            setError('Something went wrong, please try again!')
        }
        setLoginWithEmailInstructions([
            '',
            'dark hidden',
        ])
    },[loginWithEmailError,loginWithEmailSuccess,setError])

    useEffect(() => {
        if(loginEmailSent) {
            setTimeout(() => {
                setLoginEmailSent(false)
            },60000)
        }
    }
        ,[loginEmailSent])

    function handleLogin() {
        if(loginEmailSent) {
            return
        }
        const auth=getAuth()
        if(validator.email(loginEmail)[0]) {
            sendSignInLinkToEmail(auth,loginEmail,actionCodeSettings)
                .then(() => {
                    logEvent(analytics,'login_email_sent')
                    setLoginWithEmailSuccess(true)
                    window.localStorage.setItem('emailForSignIn',loginEmail)
                })
                .catch((e) => {
                    console.error(e)
                    setLoginWithEmailError(true)
                    setError('Something went wrong, please try again!')
                })
            setLoginEmailSent(true)
        } else {
            setError('Please enter a valid email address.')
            setLoginWithEmailError(true)
            document.addEventListener('keyup',() => {
                setError('')
                setLoginWithEmailError(false)
            },{once: true})
        }
    }

    return (
        <>
            <Logobar />
            <div className="flex flex-col items-center justify-center w-full gap-2 py-20 lg:gap-5">
                <form onSubmit={(e) => {e.preventDefault(); handleLogin()}} className='flex flex-col items-start justify-center'>
                    <Subheading className='!text-dark'>Login</Subheading>
                    <Input
                        className={loginWithEmailError? ' !border-danger':''}
                        value={loginEmail}
                        setValue={setLoginEmail}
                        name="Email"
                        type='email'
                        placeholder="name@company.co"
                    />
                    <ButtonPrimary className="mt-2 lg:!w-128 mx-auto" type='submit'>
                        Continue
                    </ButtonPrimary>
                    <Text
                        className={
                            'w-80 lg:w-128 text-center'+
                            ' '+
                            `!text-${loginWithEmailInstructions[1]}`
                        }>
                        {loginWithEmailInstructions[0]}
                    </Text>
                </form>
                <Text className='!text-zinc-500 border-b border-zinc-400 w-80  lg:!w-128 text-center leading-[0.01em] mt-2'><span className='px-4 mt-1 lg:pt-4 bg-light'>or</span></Text>
                <div>
                    <SignInWithGoogle redirect={redirect} />
                </div>
            </div>
        </>
    )
}

import {getFunctions,httpsCallable,connectFunctionsEmulator} from '@firebase/functions';
import firebase from '../helpers/firebase'


const functions=getFunctions();

// connectFunctionsEmulator(functions,"127.0.0.1",5001);

const createOrUpdateProject=httpsCallable(functions,'createOrUpdateProject');
const deleteProject=httpsCallable(functions,'deleteProject');
const getProjects=httpsCallable(functions,'getProjects');
const getProject=httpsCallable(functions,'getProject');
const getCredits=httpsCallable(functions,'getCredits');
const createPortalLink=httpsCallable(functions,'ext-firestore-stripe-payments-createPortalLink')
const getGenkitCompletion=httpsCallable(functions,'getGenkitCompletion');
const getGenkitSuggestions=httpsCallable(functions,'getGenkitSuggestions');
const getGenkitChat=httpsCallable(functions,'getGenkitChat');
const getStructuredCompletion=httpsCallable(functions,'aiFunctions-getStructuredCompletion');
const getStructuredSuggestions=httpsCallable(functions,'aiFunctions-getStructuredSuggestions');
const getStructuredChat=httpsCallable(functions,'aiFunctions-getStructuredChat');
const getStructuredGeneration=httpsCallable(functions,'aiFunctions-getStructuredGeneration');

export {createOrUpdateProject,deleteProject,getProject,getCredits,createPortalLink,getProjects,getGenkitCompletion,getGenkitSuggestions,getGenkitChat,getStructuredCompletion,getStructuredSuggestions,getStructuredChat,getStructuredGeneration};


import React,{useContext,useEffect,useState} from 'react'
import ProjectsContext from '../../contexts/ProjectsContext'
import ProjectCard from '../../components/Cards/ProjectCard'
import Card from '../../components/Cards/Card'
import Text from '../../components/Text/Text'
import Subheading from '../../components/Text/Subheading'
import {useNavigate} from 'react-router-dom'
import ErrorContext from '../../contexts/ErrorContext'
import Loading from '../Loading'
import usePageTitle from '../../hooks/usePageTitle'
import {logEvent} from 'firebase/analytics'
import analytics from '../../helpers/analytics'
import {getUser,rt} from '../../helpers/firebase'
import UserContext from '../../contexts/UserContext'
import {push,ref} from 'firebase/database'
import {prodErrorMap} from 'firebase/auth'
import useUserSubscription from '../../hooks/useUserSubscription'


const newProcessProject={
    title: 'Untitled Process Flow',
    nodes: [
        {
            "width": 140,
            "height": 36,
            "positionAbsolute": {
                "y": 0,
                "x": 10
            },
            "position": {
                "x": 10,
                "y": 0
            },
            "type": "squircleNode",
            "data": {
                "text": "",
                "color": "white"
            },
            "style": {
                "width": 140
            },
            "selected": false,
            "id": "aWXtm8E05ozVlL1A3RRy7",
            "dragging": false
        },
        {
            "width": 140,
            "position": {
                "x": -190,
                "y": 70
            },
            "selected": false,
            "height": 36,
            "positionAbsolute": {
                "y": 70,
                "x": -190
            },
            "type": "squircleNode",
            "id": "2gEsEZBZ6zjAnlkMIaSiX",
            "data": {
                "text": "",
                "color": "green"
            },
            "dragging": false,
            "style": {
                "width": 140
            }
        },
        {
            "selected": false,
            "position": {
                "x": -190,
                "y": -70
            },
            "dragging": false,
            "data": {
                "text": "",
                "color": "green"
            },
            "width": 140,
            "positionAbsolute": {
                "y": -70,
                "x": -190
            },
            "type": "squircleNode",
            "height": 36,
            "style": {
                "width": 140
            },
            "id": "i6NO9MVbjUxRsA2wYoFQa"
        },
        {
            "position": {
                "x": 200,
                "y": -100
            },
            "style": {
                "width": 140
            },
            "height": 36,
            "positionAbsolute": {
                "x": 200,
                "y": -100
            },
            "data": {
                "color": "green",
                "text": ""
            },
            "width": 140,
            "dragging": false,
            "type": "rectNode",
            "id": "cO3EFNY2WEZ9JwJ1cbiF7",
            "selected": false
        },
        {
            "data": {
                "text": "",
                "color": "green"
            },
            "selected": false,
            "id": "ZFoAjeZsDbovYNI6ymUeS",
            "dragging": false,
            "position": {
                "y": 30,
                "x": 200
            },
            "style": {
                "width": 140
            },
            "positionAbsolute": {
                "x": 200,
                "y": 30
            },
            "height": 83,
            "width": 140,
            "type": "diamondNode"
        },
        {
            "positionAbsolute": {
                "x": 400,
                "y": 10
            },
            "position": {
                "x": 400,
                "y": 10
            },
            "data": {
                "color": "white",
                "text": ""
            },
            "height": 36,
            "id": "R-SKxH1v7vrDB6tSyeGKB",
            "width": 140,
            "selected": false,
            "type": "rectNode",
            "dragging": false,
            "style": {
                "width": 140
            }
        },
        {
            "id": "6ZtrZrF2v016roLEQO_LQ",
            "width": 140,
            "height": 36,
            "data": {
                "text": "",
                "color": "white"
            },
            "positionAbsolute": {
                "x": 400,
                "y": 130
            },
            "dragging": false,
            "type": "rectNode",
            "style": {
                "width": 140
            },
            "selected": false,
            "position": {
                "x": 400,
                "y": 130
            }
        }
    ],
    edges: [
        {
            "markerEnd": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "markerStart": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "source": "i6NO9MVbjUxRsA2wYoFQa",
            "targetHandle": "aWXtm8E05ozVlL1A3RRy7d",
            "id": "reactflow__edge-i6NO9MVbjUxRsA2wYoFQai6NO9MVbjUxRsA2wYoFQad-aWXtm8E05ozVlL1A3RRy7aWXtm8E05ozVlL1A3RRy7c",
            "sourceHandle": "i6NO9MVbjUxRsA2wYoFQab",
            "target": "aWXtm8E05ozVlL1A3RRy7"
        },
        {
            "sourceHandle": "2gEsEZBZ6zjAnlkMIaSiXb",
            "markerEnd": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "targetHandle": "aWXtm8E05ozVlL1A3RRy7d",
            "id": "reactflow__edge-2gEsEZBZ6zjAnlkMIaSiX2gEsEZBZ6zjAnlkMIaSiXd-aWXtm8E05ozVlL1A3RRy7aWXtm8E05ozVlL1A3RRy7c",
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "source": "2gEsEZBZ6zjAnlkMIaSiX",
            "target": "aWXtm8E05ozVlL1A3RRy7"
        },
        {
            "markerEnd": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "id": "reactflow__edge-aWXtm8E05ozVlL1A3RRy7aWXtm8E05ozVlL1A3RRy7d-cO3EFNY2WEZ9JwJ1cbiF7cO3EFNY2WEZ9JwJ1cbiF7c",
            "source": "aWXtm8E05ozVlL1A3RRy7",
            "targetHandle": "cO3EFNY2WEZ9JwJ1cbiF7d",
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "target": "cO3EFNY2WEZ9JwJ1cbiF7",
            "sourceHandle": "aWXtm8E05ozVlL1A3RRy7b"
        },
        {
            "target": "ZFoAjeZsDbovYNI6ymUeS",
            "sourceHandle": "aWXtm8E05ozVlL1A3RRy7b",
            "id": "reactflow__edge-aWXtm8E05ozVlL1A3RRy7aWXtm8E05ozVlL1A3RRy7d-ZFoAjeZsDbovYNI6ymUeSZFoAjeZsDbovYNI6ymUeSc",
            "markerStart": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "targetHandle": "ZFoAjeZsDbovYNI6ymUeSd",
            "source": "aWXtm8E05ozVlL1A3RRy7",
            "markerEnd": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            }
        },
        {
            "targetHandle": "R-SKxH1v7vrDB6tSyeGKBd",
            "markerEnd": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "sourceHandle": "ZFoAjeZsDbovYNI6ymUeSb",
            "target": "R-SKxH1v7vrDB6tSyeGKB",
            "source": "ZFoAjeZsDbovYNI6ymUeS",
            "id": "reactflow__edge-ZFoAjeZsDbovYNI6ymUeSZFoAjeZsDbovYNI6ymUeSd-R-SKxH1v7vrDB6tSyeGKBR-SKxH1v7vrDB6tSyeGKBc",
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            }
        },
        {
            "targetHandle": "6ZtrZrF2v016roLEQO_LQd",
            "target": "6ZtrZrF2v016roLEQO_LQ",
            "sourceHandle": "ZFoAjeZsDbovYNI6ymUeSc",
            "id": "reactflow__edge-ZFoAjeZsDbovYNI6ymUeSZFoAjeZsDbovYNI6ymUeSb-6ZtrZrF2v016roLEQO_LQ6ZtrZrF2v016roLEQO_LQc",
            "markerStart": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "source": "ZFoAjeZsDbovYNI6ymUeS",
            "markerEnd": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            }
        }
    ],
    gridScale: 10,
    chats: [],
    files: [],
    public: false,
    updatedAt: Date.now(),
}

const newMindMapProject={
    title: 'Untitled Mind Map',
    nodes: [
        {
            "style": {
                "width": 140
            },
            "dragging": false,
            "width": 140,
            "type": "squircleNode",
            "height": 36,
            "position": {
                "x": 260,
                "y": 10
            },
            "data": {
                "text": ""
            },
            "positionAbsolute": {
                "y": 10,
                "x": 260
            },
            "selected": false,
            "id": "s5z7hDeWR0bvipfDJ0wA8"
        },
        {
            "width": 140,
            "type": "squircleNode",
            "dragging": false,
            "positionAbsolute": {
                "x": 70,
                "y": 150
            },
            "height": 36,
            "id": "6c86LjYuNIq1bqHzep2XK",
            "style": {
                "width": 140
            },
            "data": {
                "text": ""
            },
            "selected": false,
            "position": {
                "x": 70,
                "y": 150
            }
        },
        {
            "style": {
                "width": 140
            },
            "dragging": false,
            "position": {
                "y": 50,
                "x": 70
            },
            "positionAbsolute": {
                "y": 50,
                "x": 70
            },
            "id": "5JShBBPpewdzVN7Sftp5_",
            "width": 140,
            "height": 36,
            "selected": false,
            "data": {
                "text": ""
            },
            "type": "squircleNode"
        },
        {
            "data": {
                "color": "green",
                "text": ""
            },
            "selected": false,
            "id": "f1CdUv4SmxY1TpkgOe2zT",
            "style": {
                "width": 140
            },
            "position": {
                "y": 100,
                "x": -110
            },
            "positionAbsolute": {
                "y": 100,
                "x": -110
            },
            "type": "squircleNode",
            "height": 36,
            "width": 140,
            "dragging": false
        },
        {
            "dragging": false,
            "position": {
                "x": 260,
                "y": 150
            },
            "width": 140,
            "style": {
                "width": 140
            },
            "type": "squircleNode",
            "selected": false,
            "data": {
                "text": ""
            },
            "positionAbsolute": {
                "y": 150,
                "x": 260
            },
            "id": "6x-1X-x1MhVWCthTxg-DP",
            "height": 36
        },
        {
            "id": "lFQZEP_ZzQlCtOalTh6ol",
            "height": 36,
            "positionAbsolute": {
                "x": 260,
                "y": 70
            },
            "selected": false,
            "width": 140,
            "type": "squircleNode",
            "dragging": false,
            "style": {
                "width": 140
            },
            "position": {
                "x": 260,
                "y": 70
            },
            "data": {
                "text": "",
                "color": "green"
            }
        }
    ],
    edges: [
        {
            "target": "bye",
            "source": "hello",
            "id": "hello-bye",
            "type": "simplebezier"
        },
        {
            "id": "reactflow__edge-f1CdUv4SmxY1TpkgOe2zTf1CdUv4SmxY1TpkgOe2zTd-5JShBBPpewdzVN7Sftp5_5JShBBPpewdzVN7Sftp5_c",
            "sourceHandle": "f1CdUv4SmxY1TpkgOe2zTb",
            "target": "5JShBBPpewdzVN7Sftp5_",
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "targetHandle": "5JShBBPpewdzVN7Sftp5_d",
            "markerEnd": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "source": "f1CdUv4SmxY1TpkgOe2zT"
        },
        {
            "sourceHandle": "f1CdUv4SmxY1TpkgOe2zTb",
            "targetHandle": "6c86LjYuNIq1bqHzep2XKd",
            "target": "6c86LjYuNIq1bqHzep2XK",
            "source": "f1CdUv4SmxY1TpkgOe2zT",
            "id": "reactflow__edge-f1CdUv4SmxY1TpkgOe2zTf1CdUv4SmxY1TpkgOe2zTd-6c86LjYuNIq1bqHzep2XK6c86LjYuNIq1bqHzep2XKc",
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "markerEnd": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            }
        },
        {
            "target": "s5z7hDeWR0bvipfDJ0wA8",
            "sourceHandle": "5JShBBPpewdzVN7Sftp5_b",
            "targetHandle": "s5z7hDeWR0bvipfDJ0wA8d",
            "markerEnd": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "markerStart": {
                "color": "rgba(0,0,0,0)",
                "type": "arrowclosed"
            },
            "id": "reactflow__edge-5JShBBPpewdzVN7Sftp5_5JShBBPpewdzVN7Sftp5_d-s5z7hDeWR0bvipfDJ0wA8s5z7hDeWR0bvipfDJ0wA8c",
            "source": "5JShBBPpewdzVN7Sftp5_"
        },
        {
            "target": "lFQZEP_ZzQlCtOalTh6ol",
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "markerEnd": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "source": "5JShBBPpewdzVN7Sftp5_",
            "sourceHandle": "5JShBBPpewdzVN7Sftp5_b",
            "targetHandle": "lFQZEP_ZzQlCtOalTh6old",
            "id": "reactflow__edge-5JShBBPpewdzVN7Sftp5_5JShBBPpewdzVN7Sftp5_d-lFQZEP_ZzQlCtOalTh6ollFQZEP_ZzQlCtOalTh6olc"
        },
        {
            "markerStart": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "target": "6x-1X-x1MhVWCthTxg-DP",
            "id": "reactflow__edge-6c86LjYuNIq1bqHzep2XK6c86LjYuNIq1bqHzep2XKd-6x-1X-x1MhVWCthTxg-DP6x-1X-x1MhVWCthTxg-DPc",
            "markerEnd": {
                "type": "arrowclosed",
                "color": "rgba(0,0,0,0)"
            },
            "source": "6c86LjYuNIq1bqHzep2XK",
            "targetHandle": "6x-1X-x1MhVWCthTxg-DPd",
            "sourceHandle": "6c86LjYuNIq1bqHzep2XKb"
        }
    ],
    gridScale: 10,
    chats: [],
    files: [],
    public: false,
    updatedAt: Date.now(),
}

export default function Projects() {
    const [pageTitle,setPageTitle]=usePageTitle('Projects')

    const [projects,updateProjects]=useContext(ProjectsContext)
    const [projectLoading,setProjectLoading]=useState(false)
    const [user]=useContext(UserContext)
    const [loading,setLoading]=useState(false)
    const {getUserPlan}=useUserSubscription()
    const setError=useContext(ErrorContext)
    const navigate=useNavigate()

    useEffect(() => {
        updateProjects()
    },[updateProjects])

    if(loading) {
        return <Loading inline />
    }

    const handleCreateProject=async (projectType) => {
        if(projectLoading) return;
        setProjectLoading(true)
        let newProject
        if(projectType==='process') {
            newProject=newProcessProject
        } else if(projectType==='mindmap') {
            newProject=newMindMapProject
        } else {
            newProject={
                title: 'Untitled Flowchart',
                nodes: [],
                edges: [],
                files: [],
                chats: [],
                gridScale: 10,
                public: false,
                updatedAt: Date.now(),
            }
        }
        const ownProjects=projects.filter(project => project.ownerId===user.uid)
        const userPlan=await getUserPlan()
        console.log(userPlan,ownProjects)
        if((userPlan==='free'&&ownProjects.length>=5)) {
            setError('You have reached the maximum number of projects allowed on your plan')
            navigate('/pricing')
            return
        }
        return push(ref(rt,'projects/'+user.uid),newProject).then(data => {
            setProjectLoading(false)
            const projectId=data.key
            if(data) {
                logEvent(analytics,'new_project',{
                    project_id: projectId
                })
                setLoading(true)
                updateProjects();
                setLoading(false)
                navigate('/dashboard/design/'+projectId)
            } else {
                logEvent(analytics,'new_project_error')
            }
        })
    }

    return (
        <div className='w-full p-2'>
            <div className="flex flex-col items-center justify-center max-w-6xl min-w-full gap-10 px-0 lg:min-w-full md:px-10 lg:px-8 xl:px-8 md:w-128 lg:w-auto lg:pt-10">
                <div className="w-full">
                    <div className="grid w-full grid-cols-1 grid-rows-3 gap-2 mt-5 lg:w-2/3 lg:grid-cols-3 lg:grid-rows-1 lg:gap-2">
                        <Card
                            onClick={() => handleCreateProject()}
                            className="flex !justify-start items-center !flex-row gap-1">
                            <i className={`leading-6 align-middle bi ${projectLoading? 'animate-spin bi-bounding-box-circles text-xl':'bi-plus text-2xl'}`}></i>
                            <Text className="!align-middle font-medium">New Project</Text>
                        </Card>
                        <Card
                            onClick={() => handleCreateProject('process')}
                            className="flex !justify-start items-center !flex-row gap-1">
                            <i className="text-xl leading-6 align-middle bi bi-bounding-box-circles"></i>
                            <Text className="!align-middle font-medium">New Process Flow</Text>
                        </Card>
                        <Card
                            onClick={() => handleCreateProject('mindmap')}
                            className="flex !justify-start items-center !flex-row gap-1">
                            <i className="text-2xl leading-6 align-middle bi bi-diagram-2"></i>
                            <Text className="!align-middle font-medium">
                                New Mind Map
                            </Text>
                        </Card>
                    </div>
                </div>
                <div className="w-full">
                    <Subheading className="pb-0 border-b border-b-primary-400">
                        My Projects
                    </Subheading>
                    <div className="grid w-full min-w-0 min-h-0 grid-cols-1 gap-4 pb-8 mt-5 lg:grid-cols-5 lg:gap-3">
                        {
                            projects.map((project) =>
                                <ProjectCard
                                    to={`design/${project.link}`}
                                    key={project.id}
                                    project={project}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

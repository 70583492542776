import React from 'react'
import usePageTitle from '../../hooks/usePageTitle'

export default function UserSurveyOne() {
    const [pageTitle,setPageTitle]=usePageTitle('User Experience Survey')

    return (
        <div className='w-full min-h-screen'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdNwhkO0AtK35n1vuQ8FIMgkswy8sWXRD4fTRpYL-OfxmL15g/viewform?embedded=true" className='w-full min-h-screen' frameborder="0" title='User Experience Survey' marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}

import React,{useEffect,useContext,useState} from 'react'
import usePageTitle from '../../hooks/usePageTitle'
import UserContext from '../../contexts/UserContext'
import {collection,doc,updateDoc} from 'firebase/firestore'
import {db} from '../../helpers/firebase'
import Subheading from '../../components/Text/Subheading'
import ButtonSecondary from '../../components/Buttons/ButtonSecondary'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import {useNavigate} from 'react-router-dom'

export default function Unsubscribe() {
    const [pageTitle,setPageTitle]=usePageTitle('Unsubscribe')
    const navigate=useNavigate()
    const [unsubscribed,setUnsubscribed]=useState(false)
    const [user]=useContext(UserContext)

    function unsubscribe() {
        updateDoc(doc(db,'users',user.uid),{unsubscribed: true}).then(() => {
            setUnsubscribed(true)
            navigate('/dashboard')
        })
    }

    function subscribe() {
        updateDoc(doc(db,'users',user.uid),{unsubscribed: false}).then(() => {
            setUnsubscribed(false)
            navigate('/dashboard')
        })
    }

    return (
        <div className='flex items-center justify-center w-full min-h-screen bg-light'>
            <div className='flex flex-col justify-center w-4/5 gap-3 p-4 rounded-lg shadow-md bg-zinc-100 lg:w-1/4 items-left '>
                <Subheading>Do you want to continue receiving emails from us?</Subheading>
                <div className='flex justify-center w-full gap-2'>
                    <ButtonPrimary onClick={subscribe} className=''>Yes</ButtonPrimary>
                    <ButtonSecondary onClick={unsubscribe} className='bg-light'>No</ButtonSecondary>
                </div>
            </div>
        </div>
    )
}
import {getAuth} from 'firebase/auth'
import {useCallback} from 'react'

export default function useUserSubscription() {

    const auth=getAuth()

    function getIsPremium() {
        return auth.currentUser?.getIdTokenResult(true).then((result) => {
            if(result.claims.stripeRole==='premium') {
                return true
            }
            return false
        })
    }

    const getUserPlan=useCallback(() => {
        return auth.currentUser?.getIdTokenResult(true).then((result) => {
            switch(result.claims.stripeRole) {
                case 'premium':
                    return 'premium'
                case 'plus':
                    return 'plus'
                default:
                    return 'free'
            }
        })
    },[auth.currentUser])

    function getUserStripeId() {
        return auth.currentUser?.getIdTokenResult(true).then((result) => {
            return result.claims.stripeId
        })
    }

    return {getIsPremium,getUserPlan}
}

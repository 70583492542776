/** @format */

// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import {getDatabase} from 'firebase/database'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig={
  apiKey: "AIzaSyD35B0-hAx5LbVLHZWM9erbSqOdxTtqM9g",
  authDomain: "auth.flowsage.co",
  projectId: "flowsage-web",
  storageBucket: "flowsage-web.appspot.com",
  messagingSenderId: "92334233992",
  appId: "1:92334233992:web:e5bfa0c7ea868e09ff423d",
  measurementId: "G-XCNPQSZVLT",
  databaseURL: "https://flowsage-web-default-rtdb.firebaseio.com"
}

// Initialize Firebase
const app=initializeApp(firebaseConfig)
const auth=getAuth(app)
const db=getFirestore(app)
const rt=getDatabase(app)
const storage=getStorage(app)

function getUser() {
  return auth.currentUser
}

export {auth,getUser,db,storage,rt}
export default app

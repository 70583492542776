export const useEnv=() => {
    const env='prod'
    return {
        getEnv: () => {
            return env
        },isProd: () => {
            return env==='prod'
        },
        getBaseUrl: () => {
            switch(env) {
                case 'prod':
                    return 'https://flowsage.co'
                case 'beta':
                    return 'https://lab.flowsage.co'
                case 'dev':
                    return 'http://localhost:3000'
                default:
                    return 'https://flowsage.co'
            }
        }
    }
}

/** @format */

import React from 'react'
import icon from '../../assets/icon_light.svg'
import {Link} from 'react-router-dom'

export default function Logobar() {
  return (
    <nav className="flex justify-between w-full p-2 align-center">
      <div className="hidden w-1/12 my-auto md:block max-w-2tiny" style={{
        maxWidth: '200px'
      }}>
        <Link to='/'>
          <img src={icon} alt="logo" className="lg:p-5" />
        </Link>
      </div>
      <div className="w-1/5 py-2 my-auto md:hidden max-w-3tiny" style={{
        maxWidth: '100px'
      }}>
        <Link to='/'>
          <img src={icon} alt="logo" />
        </Link>
      </div>
    </nav>
  )
}

import {deleteUser,getAuth,GoogleAuthProvider,reauthenticateWithRedirect,sendSignInLinkToEmail,ProviderId,isSignInWithEmailLink,reauthenticateWithCredential,EmailAuthProvider} from 'firebase/auth'
import React,{useContext,useEffect,useState} from 'react'
import {useNavigate,useParams} from 'react-router-dom'
import Loading from './Loading'
import Subheading from '../components/Text/Subheading'
import Text from '../components/Text/Text'
import Popup from '../components/Popups/Popup'
import usePageTitle from '../hooks/usePageTitle'
import ErrorContext from '../contexts/ErrorContext'
import useSEO from '../hooks/useSEO'

export default function DeleteAccount() {
    const [pageTitle,setPageTitle]=usePageTitle('Delete you account')
    useSEO({indexable: false})

    const setError=useContext(ErrorContext)

    const auth=getAuth()
    const navigate=useNavigate()
    const {uid}=useParams()

    const [showReauthenticateWithLinkPopup,setShowReauthenticateWithLinkPopup]=useState(false)

    useEffect(() => {
        if(isSignInWithEmailLink(auth,window.location.href)) {
            const credential=EmailAuthProvider.credentialWithLink(auth.currentUser.email,window.location.href)
            reauthenticateWithCredential(auth.currentUser,credential).then(() => {
                deleteUser(auth.currentUser).then(() => {
                    navigate('/login')
                })
            })
        } else {
            if(window.confirm('Are you sure you want to delete your account?')) {
                if(uid===auth.currentUser.uid) {
                    deleteUser(auth.currentUser).then(() => {
                        navigate('/login')
                    }).catch(() => {
                        if(auth.currentUser.providerData[0].providerId===ProviderId.GOOGLE) {
                            const provider=new GoogleAuthProvider()
                            reauthenticateWithRedirect(auth.currentUser,provider)
                        } else {
                            setShowReauthenticateWithLinkPopup(true)
                            const actionCodeSettings={
                                url: 'http://localhost:3000/delete/'+auth.currentUser.uid,
                                handleCodeInApp: true
                            }
                            sendSignInLinkToEmail(auth,auth.currentUser.email,actionCodeSettings)
                        }
                    })
                } else {
                    navigate('/dashboard')
                    setError('Your account couldn\'t be deleted.')
                }
            }
        }
    },[navigate,auth,uid,setError])

    return (
        <>
            {showReauthenticateWithLinkPopup? <Popup type='danger' show={showReauthenticateWithLinkPopup} setShow={setShowReauthenticateWithLinkPopup}>
                <Subheading>Delete Account</Subheading>
                <Text>
                    To delete your account, you need to login again. Follow the link that has been sent to your email to successfully delete your account.
                </Text>
            </Popup>:
                <Loading />}
        </>)
}

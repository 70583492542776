import React,{useContext} from 'react'
import Node from './Node'
import NodeContext from '../../contexts/NodeContext'
import icon from '../../assets/nodes/CloudNode.svg'
import useColors from '../../hooks/useColors'

export default function CloudNode({id,data,selected,...props}) {
    const [setNodeValue,getNodeValue]=useContext(NodeContext)
    const {getColorToFilter}=useColors()

    return (
        <Node nodeId={id} className='relative text-xs' selected={selected} approved={typeof data?.approved==='boolean'? data?.approved:true}{...props} data={data}><img src={icon} style={{filter: 'drop-shadow(0px 4px 6px rgba(0,0,0,0.05)) '+getColorToFilter(data?.color)}} alt='CloudNode' className='w-full' /><input className='absolute right-0 text-center -translate-x-1/2 -translate-y-1/2 bg-transparent left-1/2 top-1/2 focus:border-none focus:outline-none' onChange={e => {e.preventDefault(); setNodeValue(id,e.target.value)}} value={data?.text} /></Node>
    )
}

/** @format */

import {setAnalyticsCollectionEnabled,setUserId} from 'firebase/analytics'
import React,{useContext,useEffect,useState,useRef} from 'react'
import {Outlet,useNavigate} from 'react-router-dom'
import UserContext from '../contexts/UserContext'
import Loading from '../pages/Loading'
import analytics from '../helpers/analytics'
import useAnalyticsConsent from '../hooks/useAnalyticsConsent'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

export default function Authenticated({children,...props}) {
  let navigate=useNavigate()
  const [user,loading,error]=useContext(UserContext)
  const [tawkLoaded,setTawkLoaded]=useState()
  const tawkMessengerRef=useRef(null)

  useEffect(() => {
    if(tawkLoaded) {
      if(!loading&&user) {
        tawkMessengerRef.current.setAttributes({
          name: user.displayName,
          email: user.email,
          id: user.uid
        })
      } else {
        tawkMessengerRef.current?.setAttributes({
          name: 'Guest',
          email: '',
          id: ''
        })
      }
    }
  },[loading,user,tawkLoaded])

  useEffect(() => {
    if(!user&&!loading) {
      const pathname=window.location.pathname
      navigate('/login/'+encodeURIComponent(pathname))
    }
    if(error) {
      navigate('/error')
    }
    if(user) {
      setUserId(analytics,user?.uid)
    }
  },[user,loading,navigate,error])

  if(loading||!user) {
    return <Loading />
  }

  return (
    <div className="w-full min-h-screen p-0 pb-8 m-0 overflow-x-hidden lg:pb-0">
      <Outlet />
      {!window.location.pathname.includes('design')&&<TawkMessengerReact propertyId='66ba0b45146b7af4a4395f33' onLoad={() => setTawkLoaded(true)} widgetId='1i53bo206' ref={tawkMessengerRef} />}
    </div>
  )
}

import React,{useContext,useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Subheading from '../Text/Subheading'
import Text from '../Text/Text'
import ButtonPrimary from '../Buttons/ButtonPrimary'
import Card from './Card'
import UserContext from '../../contexts/UserContext'

export default function FeedbackCard() {
    const navigate=useNavigate()
    const [submitted,setSubmitted]=useState(true)
    const [user]=useContext(UserContext)

    useEffect(() => {
        const feedbackSubmitted=localStorage.getItem('feedbackSubmitted')
        const signupDate=user?.metadata.creationTime
        const newUser=signupDate&&new Date()-new Date(signupDate)<3*24*60*60*1000
        if(!feedbackSubmitted&&!newUser) {
            setSubmitted(false)
        }
    },[])

    function submitFeedback() {
        localStorage.setItem('feedbackSubmitted',true)
        setSubmitted(true)
        navigate('/forms/experience')
    }

    return (
        <>
            {!submitted&&<div className='fixed bottom-6 right-6 lg:w-128'>
                <Card className='border border-primary'>
                    <Subheading className='py-0' >We need your help!</Subheading>
                    <Text>Help us improve your experience. Answer a few quick questions.</Text>
                    <ButtonPrimary className='mt-4' onClick={submitFeedback}>Take the survey</ButtonPrimary>
                </Card>
            </div>}
        </>
    )
}

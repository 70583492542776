import React,{useContext,useState,useEffect} from 'react'
import Node from './Node'
import NodeContext from '../../contexts/NodeContext'
import icon from '../../assets/nodes/SquircleNode.svg'
import useColors from '../../hooks/useColors'
import useValidator from '../../hooks/useValidator'
import classNames from 'classnames'
import {set} from 'firebase/database'

export default function EmbedNode({id,data,selected,className='',domain='',transformUrl=(url) => url,text='Embed Link',...props}) {
    const [setNodeValue,getNodeValue]=useContext(NodeContext)
    const {getColorToFilter}=useColors()
    const validator=useValidator()
    const [embedLink,setEmbedLink]=useState('')

    const handleEnterUrl=(e) => {
        e.preventDefault()
        if(e.key==='Enter') {
            if(validator.url(data?.text)&&new URL(data?.text).hostname.includes(domain)) {
                setEmbedLink(transformUrl(data?.text))
                setNodeValue(id,300,'style.width')
            }
        }
    }

    useEffect(() => {
        setNodeValue(id,text)
        if(validator.url(data?.text)&&new URL(data?.text).hostname.includes(domain)) {
            setEmbedLink(transformUrl(data?.text))
            setNodeValue(id,300,'style.width')
        }
        console.log(props)
    },[])

    if(embedLink) {
        return (
            <Node keepAspectRatio={false} nodeId={id} className={classNames('relative w-full h-full text-xs',className)} selected={selected} approved={typeof data?.approved==='boolean'? data?.approved:true}{...props} data={data}><div className='w-full h-full p-2 bg-white border rounded-md '><iframe src={embedLink} className='w-full h-full' title='EmbedNode' /></div></Node>
        )
    }




    return (
        !embedLink&&<Node nodeId={id} className='relative text-xs' selected={selected} approved={typeof data?.approved==='boolean'? data?.approved:true}{...props} data={data}><img src={icon} style={{filter: 'drop-shadow(0px 4px 6px rgba(0,0,0,0.05)) '+getColorToFilter(data?.color)}} alt='SquircleNode' className='w-full' /><input onKeyUp={handleEnterUrl} className='absolute right-0 w-4/5 text-center -translate-x-1/2 -translate-y-1/2 bg-transparent left-1/2 top-1/2 focus:border-none focus:outline-none' onChange={e => {e.preventDefault(); setNodeValue(id,e.target.value)}} value={data?.text} /><div className='absolute w-[85%] px-0.5 py-0.5 text-center left-1/2 bg-opacity-70 -translate-x-1/2 text-white rounded-md top-[110%] bg-dark-400 !text-3xs'>Paste URL and press Enter</div></Node>
    )
}

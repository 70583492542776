/** @format */

import React,{useContext,useEffect,useMemo,useState} from 'react'
import Navbar from '../../components/Nav/Navbar'
import {addDoc,deleteDoc,collection,onSnapshot,doc,getDoc} from 'firebase/firestore'
import {db} from '../../helpers/firebase'
import Heading from '../../components/Text/Heading'
import Subheading from '../../components/Text/Subheading'
import Text from '../../components/Text/Text'
import Toggle from '../../components/Inputs/Toggle'
import ButtonSecondary from '../../components/Buttons/ButtonSecondary'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import usePageTitle from '../../hooks/usePageTitle'
import HeroHeading from '../../components/Text/HeroHeading'
import FeaturesListArtisan from '../../components/Lists/FeaturesListArtisan'
import FeaturesListStarter from '../../components/Lists/FeaturesListStarter'
import UserContext from '../../contexts/UserContext'
import {useNavigate} from 'react-router-dom'
import Logobar from '../../components/Nav/Logobar'
import ErrorContext from '../../contexts/ErrorContext'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import bg from '../../assets/bg.png'
import FaqCard from '../../components/Cards/FaqCard'
import Loading from '../Loading'
import useDelay from '../../hooks/useDelay'
import {useEnv} from '../../hooks/useEnv'
import {logEvent} from 'firebase/analytics'
import analytics from '../../helpers/analytics'

export default function Pricing() {
    const [pageTitle,setPageTitle]=usePageTitle('Pricing')
    const [checkoutLoading,setCheckoutLoading]=useState(false)
    const [proCheckoutLinks,setProCheckoutLinks]=useState({monthly: '',annually: ''})
    const [plusCheckoutLinks,setPlusCheckoutLinks]=useState({monthly: '',annually: ''})
    const [proCheckoutIds,setProCheckoutIds]=useState({monthly: '',annually: ''})
    const [plusCheckoutIds,setPlusCheckoutIds]=useState({monthly: '',annually: ''})
    const navigate=useNavigate()
    const [user]=useContext(UserContext)
    const setError=useContext(ErrorContext)
    const delay=useDelay()
    const {getBaseUrl}=useEnv()

    const [isMonthly,setIsMonthly]=useState(true)

    const proPrices={
        monthly: 'price_1P4pn8LBkoWM0o7Bm1aaerfk',
        annually: 'price_1P4po4LBkoWM0o7BlZmePoyR'
    }

    const plusPrices={
        monthly: 'price_1P4ppSLBkoWM0o7BTlaXPC2I',
        annually: 'price_1P4ppiLBkoWM0o7B6dtOvUp8'
    }

    const ltvMultipleMonths=3

    const handleGetCheckoutLinks=async () => {
        if(user) {
            const stripeId=(await getDoc(doc(db,'users',user.uid))).data()?.stripeId
            if(!stripeId) {
                await delay(1000)
            }
            addDoc(collection(db,'users',user.uid,'checkout_sessions'),{
                price: proPrices.monthly,
                success_url: getBaseUrl()+`/dashboard?purchase_success=true&purchase_item=${encodeURIComponent('Flowsage Pro')}&purchase_value=${encodeURIComponent(14.99*ltvMultipleMonths)}&purchase_currency=USD&purchase_variant=Monthly&purchase_date=${encodeURIComponent(new Date().getTime())}&uid=${encodeURIComponent(user.uid)}&purchase_price=${encodeURIComponent(14.99)} `,
                cancel_url: getBaseUrl()+'/dashboard',
                allow_promotion_codes: true
            }).then((doc) => {
                onSnapshot(doc,async (snap) => {
                    const {error,url}=snap.data()||{}
                    if(error) {
                        setProCheckoutLinks(proCheckoutLinks => {return {...proCheckoutLinks,monthly: false}})
                    }
                    if(url) {
                        setProCheckoutLinks(proCheckoutLinks => {return {...proCheckoutLinks,monthly: url}})
                        setProCheckoutIds(proCheckoutIds => {return {...proCheckoutIds,monthly: doc.id}})
                    }
                })
            })
            addDoc(collection(db,'users',user.uid,'checkout_sessions'),{
                price: proPrices.annually,
                success_url: getBaseUrl()+`/dashboard?purchase_success=true&purchase_item=${encodeURIComponent('Flowsage Pro (Annually)')}&purchase_value=${encodeURIComponent(149.90)}&purchase_currency=USD&purchase_variant=Annually&purchase_date=${encodeURIComponent(new Date().getTime())}&uid=${encodeURIComponent(user.uid)}&purchase_price=${encodeURIComponent(149.90)} `,
                cancel_url: getBaseUrl()+'/dashboard',
                allow_promotion_codes: true
            }).then(async (doc) => {
                onSnapshot(doc,async (snap) => {
                    const {error,url}=snap.data()||{}
                    if(error) {
                        setProCheckoutLinks(proCheckoutLinks => {return {...proCheckoutLinks,annually: false}})
                    }
                    if(url) {
                        setProCheckoutLinks(proCheckoutLinks => {return {...proCheckoutLinks,annually: url}})
                        setProCheckoutIds(proCheckoutIds => {return {...proCheckoutIds,annually: doc.id}})
                    }
                })
            })
            addDoc(collection(db,'users',user.uid,'checkout_sessions'),{
                price: plusPrices.monthly,
                success_url: getBaseUrl()+`/dashboard?purchase_success=true&purchase_item=${encodeURIComponent('Flowsage Plus (Monthly)')}&purchase_value=${encodeURIComponent(9.99*ltvMultipleMonths)}&purchase_currency=USD&purchase_variant=Monthly&purchase_date=${encodeURIComponent(new Date().getTime())}&uid=${encodeURIComponent(user.uid)}&purchase_price=${encodeURIComponent(9.99)} `,
                cancel_url: getBaseUrl()+'/dashboard',
                allow_promotion_codes: true
            }).then(async (doc) => {
                onSnapshot(doc,async (snap) => {
                    const {error,url}=snap.data()||{}
                    if(error) {
                        setPlusCheckoutLinks(plusCheckoutLinks => {return {...plusCheckoutLinks,monthly: false}})
                    }
                    if(url) {
                        setPlusCheckoutLinks(plusCheckoutLinks => {return {...plusCheckoutLinks,monthly: url}})
                        setPlusCheckoutIds(plusCheckoutIds => {return {...plusCheckoutIds,monthly: doc.id}})
                    }
                })
            })

            addDoc(collection(db,'users',user.uid,'checkout_sessions'),{
                price: plusPrices.annually,
                success_url: getBaseUrl()+`/dashboard?purchase_success=true&purchase_item=${encodeURIComponent('Flowsage Plus (Annually)')}&purchase_value=${encodeURIComponent(99.90)}&purchase_currency=USD&purchase_variant=Annually&purchase_date=${encodeURIComponent(new Date().getTime())}&uid=${encodeURIComponent(user.uid)}&purchase_price=${encodeURIComponent(99.90)}`,
                cancel_url: getBaseUrl()+'/dashboard',
                allow_promotion_codes: true
            }).then(async (doc) => {
                onSnapshot(doc,async (snap) => {
                    const {error,url}=snap.data()||{}
                    if(error) {
                        setPlusCheckoutLinks(plusCheckoutLinks => {return {...plusCheckoutLinks,annually: false}})
                    }
                    if(url) {
                        setPlusCheckoutLinks(plusCheckoutLinks => {return {...plusCheckoutLinks,annually: url}})
                        setPlusCheckoutIds(plusCheckoutIds => {return {...plusCheckoutIds,annually: doc.id}})
                    }
                })
            })
        }
    }

    useEffect(() => {
        handleGetCheckoutLinks()
    },[user])

    useDidMountEffect(() => {
        if(proCheckoutIds.monthly&&user) {
            deleteDoc(doc(db,'users',user?.uid,'checkout_sessions',proCheckoutIds.monthly))
            setProCheckoutIds(proCheckoutIds => {return {...proCheckoutIds,monthly: ''}})
        }
    },[proCheckoutIds.monthly])

    useDidMountEffect(() => {
        if(proCheckoutIds.annually&&user) {
            deleteDoc(doc(db,'users',user?.uid,'checkout_sessions',proCheckoutIds.annually))
            setProCheckoutIds(proCheckoutIds => {return {...proCheckoutIds,annually: ''}})
        }
    },[proCheckoutIds.annually])

    useDidMountEffect(() => {
        if(plusCheckoutIds.monthly&&user) {
            deleteDoc(doc(db,'users',user?.uid,'checkout_sessions',plusCheckoutIds.monthly))
            setPlusCheckoutIds(plusCheckoutIds => {return {...plusCheckoutIds,monthly: ''}})
        }
    },[plusCheckoutIds.monthly])

    useDidMountEffect(() => {
        if(plusCheckoutIds.annually&&user) {
            deleteDoc(doc(db,'users',user?.uid,'checkout_sessions',plusCheckoutIds.annually))
            setPlusCheckoutIds(plusCheckoutIds => {return {...plusCheckoutIds,annually: ''}})
        }
    },[plusCheckoutIds.annually])

    const handleStarter=() => {
        if(user) {
            navigate('/dashboard')
        } else {
            navigate('/login')
        }
    }

    const handlePro=async () => {
        if(user) {
            if(!proCheckoutLinks.monthly||!proCheckoutLinks.annually) {
                setCheckoutLoading('pro')
                setTimeout(() => {
                    setCheckoutLoading(false)
                    setError('Could\'t redirect to checkout. Please try again.')
                },10000)
            } else {
                logEvent(analytics,'begin_checkout',{
                    items: [{
                        item_id: isMonthly? proPrices.monthly:proPrices.annually,
                        item_name: isMonthly? 'Pro Monthly':'Pro Annually',
                        item_category: 'Subscription',
                        quantity: 1,
                        price: isMonthly? 14.99:149.90
                    }]
                })
                window.location.assign(isMonthly? proCheckoutLinks.monthly:proCheckoutLinks.annually)
            }
        } else {
            navigate('/login/$2Fpricing')
        }
    }

    const handlePlus=async () => {
        if(user) {
            if(!plusCheckoutLinks.monthly||!plusCheckoutLinks.annually) {
                setCheckoutLoading('plus')
                setTimeout(() => {
                    setCheckoutLoading(false)
                    setError('Could\'t redirect to checkout. Please try again.')
                },10000)
            } else {
                logEvent(analytics,'begin_checkout',{
                    items: [{
                        item_id: isMonthly? plusPrices.monthly:plusPrices.annually,
                        item_name: isMonthly? 'Plus Monthly':'Plus Annually',
                        item_category: 'Subscription',
                        quantity: 1,
                        price: isMonthly? 9.99:99.90
                    }]
                })
                window.location.assign(isMonthly? plusCheckoutLinks.monthly:plusCheckoutLinks.annually)
            }
        } else {
            navigate('/login/%2Fpricing')
        }
    }

    useEffect(() => {
        if(user) {
            if(checkoutLoading==='pro') {
                if((isMonthly&&proCheckoutLinks.monthly)||(!isMonthly&&proCheckoutLinks.annually)) {
                    setCheckoutLoading(false)
                    window.location.assign(isMonthly? proCheckoutLinks.monthly:proCheckoutLinks.annually)
                }
            }
            if(checkoutLoading==='plus') {
                if((isMonthly&&plusCheckoutLinks.monthly)||(!isMonthly&&plusCheckoutLinks.annually)) {
                    setCheckoutLoading(false)
                    window.location.assign(isMonthly? plusCheckoutLinks.monthly:plusCheckoutLinks.annually)
                }
            }
        }
    },[checkoutLoading,proCheckoutLinks.monthly,proCheckoutLinks.annually,plusCheckoutLinks.monthly,plusCheckoutLinks.annually,isMonthly,user])

    if(checkoutLoading) {
        return <Loading />
    }



    return (
        <div style={{backgroundImage: `url(${bg})`}} className='min-h-full overflow-scroll bg-no-repeat bg-contain'>
            <Logobar />
            <div className={`h-full p-3 lg: p-16 lg: pt-0`}>
                <div className="flex flex-col justify-center w-full p-4 px-0 pt-5 mx-auto lg:py-20 lg:px-10 align-center">
                    <HeroHeading className='lg:!font-bold !font-semibold text-center lg:block'>Flowsage Pricing</HeroHeading>
                    <Subheading className="w-full p-2 pl-0 mx-auto mt-5 text-center lg:w-2/3 lg:text-lg text-dark-200 text-md">
                        Get unlimited access to Flowsage's library of tools
                    </Subheading>
                </div>
                <div className='flex items-center justify-center w-full gap-2 mb-8 font-semibold'>
                    <Text className={'text-dark'}>Monthly</Text>
                    <Toggle checked={!isMonthly} setChecked={setIsMonthly} />
                    <Text className={'text-dark'}>Yearly (2 months free)</Text>
                </div>
                <div className="grid grid-cols-1 grid-rows-2 px-3 mx-auto rounded-md lg:grid-cols-3 lg:grid-rows-1 lg:px-0">
                    <div className="flex flex-col items-center justify-between p-5 px-8 mb-4 border border-r-0 shadow-xl lg:mb-0 rounded-xl lg:rounded-l-xl text-md bg-light">
                        <div className="w-full pt-3 text-center">
                            <Heading className="pl-0 text-2xl font-semibold">Flowsage Lite</Heading>
                        </div>
                        <div className="flex items-end justify-center w-full pt-12 pb-6 text-center">
                            <Heading className="pl-0 !text-4xl lg:text-5xl">Free</Heading>
                        </div>
                        <Text className='w-full py-0 my-0 text-left text-dark-400'>&nbsp;</Text>
                        <ButtonSecondary onClick={handleStarter} className="m-4 mt-8 rounded-md !w-full lg:w-full">{user?.uid? "Continue for free":"Sign up for free"}</ButtonSecondary>
                        <FeaturesListStarter className='self-start px-2 pt-8 text-lg leading-loose'>
                            <Text >5 AI credits</Text>
                            <Text >Up to 5 projects</Text>
                            <Text >Up to 100 shapes per project</Text>
                            <Text >Up to 1 collaborator</Text>
                            <Text negative>Access to newest features</Text>
                            <Text negative>Priority email support</Text>
                        </FeaturesListStarter>
                    </div>
                    <div className="flex flex-col lg:ml-4 items-center justify-between p-5 px-6 shadow-xl rounded-xl mb-4 lg:mb-0 bg-gradient-to-tr to-secondary-800 from-primary-700 !text-light relative">
                        <div className="w-full text-center">
                            <Heading className="pl-0 pt-3 text-2xl font-semibold !text-light">Flowsage Plus</Heading>
                        </div>
                        <div className="flex items-end justify-center w-full pt-12 pb-6 text-center">
                            <Heading className='!text-2xl lg:text-3xl !text-light'>$</Heading>
                            <Heading className="pl-0 !text-4xl lg:text-5xl !text-light">{isMonthly? '9.99':'8.33'}</Heading>
                            <Text className="pl-0.5 h-fit !text-light">/mo</Text>
                        </div>
                        <Text className='!text-light text-center w-full py-0 my-0'>{isMonthly? 'Billed Monthly':'Billed $99.90/year'}</Text>
                        {/* <Subheading className="self-start pt-12 !text-light">Includes</Subheading> */}
                        <ButtonPrimary onClick={handlePlus} className="m-4 mt-8 !w-full lg:!w-full">Upgrade now</ButtonPrimary>
                        <FeaturesListArtisan className='self-start px-2 pt-8 text-lg leading-loose'>
                            <Text className='!text-light'>150 AI credits per month</Text>
                            <Text className='!text-light'>Unlimited projects</Text>
                            <Text className='!text-light'>Unlimited shapes per project</Text>
                            <Text className='!text-light'>Up to 10 collaborators</Text>
                            <Text className='!text-light'>Access to newest features</Text>
                            <Text negative className='!text-light'>Priority email support</Text>
                        </FeaturesListArtisan>
                    </div>
                    <div className="flex flex-col lg:ml-4 items-center justify-between p-5 px-6 shadow-xl rounded-xl mb-4 lg:mb-0 bg-gradient-to-tr bg-primary-950 !text-light relative">
                        <div className="w-full text-center">
                            <Heading className="pl-0 pt-3 text-2xl font-semibold !text-light">Flowsage Pro</Heading>
                        </div>
                        <div className="flex items-end justify-center w-full pt-12 pb-6 text-center">
                            <Heading className='!text-2xl lg:text-3xl !text-light'>$</Heading>
                            <Heading className="pl-0 !text-4xl lg:text-5xl !text-light">{isMonthly? '14.99':'12.49'}</Heading>
                            <Text className="pl-1 h-fit !text-light">/mo</Text>
                        </div>
                        <Text className='!text-light text-center w-full py-0 my-0'>{isMonthly? 'Billed Monthly':'Billed $149.90/year'}</Text>
                        {/* <Subheading className="self-start pt-12 !text-light">Includes</Subheading> */}
                        <ButtonPrimary onClick={handlePro} className="m-4 mt-8 !w-full lg:!w-full">Upgrade now</ButtonPrimary>
                        <FeaturesListArtisan className='self-start px-2 pt-8 text-lg leading-loose'>
                            <Text className='!text-light'>Unlimited AI credits</Text>
                            <Text className='!text-light'>Unlimited projects</Text>
                            <Text className='!text-light'>Unlimited shapes per project</Text>
                            <Text className='!text-light'>Unlimited collaborators</Text>
                            <Text className='!text-light'>Access to newest features</Text>
                            <Text className='!text-light'>Priority email support</Text>
                        </FeaturesListArtisan>
                    </div>
                    {/* <div className="flex flex-col items-center justify-start p-5 px-6 shadow-xl lg:ml-4 rounded-xl bg-primary-950 !text-light relative">
                        <div className="w-full text-center">
                            <Heading className="pl-0 pt-3 text-2xl font-semibold !text-light">Enterprise</Heading>
                        </div>
                        <div className="flex items-end justify-center w-full pt-12 pb-6 text-center">
                            <>
                                <Heading className='!text-2xl lg:text-3xl !text-light'><span className='text-2xl'>from</span> $</Heading>
                                <Heading className="pl-0 !text-4xl lg:text-5xl !text-light">5.99</Heading>
                                <Text className="pl-1 h-fit !text-light">/user</Text></>
                        </div>
                        <Text className='!text-light text-center w-full py-0 my-0'>Custom Pricing</Text>
                        <Subheading className="self-start pt-12 !text-light">Includes</Subheading>
                    <ButtonPrimary onClick={() => window.location.href='mailto:b2b@flowsage.co'} className="m-4 mt-8 !w-full lg:!w-full">Get Started</ButtonPrimary>
                    <FeaturesListArtisan className='self-start px-2 pt-8 text-lg leading-loose '>
                        <Text className='!text-light' >Real-time collaboration</Text>
                        <Text className='!text-light'>Password-protected boards</Text>
                        <Text className='!text-light'>Single SSO Login</Text>
                        <Text className='!text-light'>Dedicated Account Manager</Text>
                        <Text className='!text-light'>Advanced automation options</Text>
                    </FeaturesListArtisan>
                    </div> */}
                </div>
                <div className='mt-16'>
                    <Subheading className='w-full pb-2 text-xl text-center lg:text-2xl'>Frequently Asked Questions</Subheading>
                    <FaqCard question={'How do AI credits work?'} answer={'AI credits can be used for advanced AI features. Each month, you will receive 5 credits with Flowsage Lite, which are consumed when utilizing certain AI functionalities, such as auto-completions, optimizations, and chats.'} />
                    <FaqCard question={'Is there a limit to the number of projects I can create?'} answer={'Yes, Flowsage Lite users can create up to 5 projects per month. If you need more project capacity, you may consider upgrading to Flowsage Pro for unlimited projects.'} />
                    <FaqCard question={'Can I upgrade at any time?'} answer={'Absolutely! Users can upgrade from Flowsage Lite to Flowsage Pro at any time. Simply visit your account settings or the pricing page to make the switch.'} />
                    <FaqCard question={'What happens if I exceed the shape limit on my project?'} answer={'Flowsage Lite allows up to 10 shapes per project. If you exceed this limit, you may need to either delete shapes to stay within the limit or consider upgrading to Flowsage Pro for unlimited shapes per project.'} />
                    <FaqCard question={'Can I cancel my Flowsage Pro subscription at any time?'} answer={'Yes, you can cancel your Flowsage Pro subscription at any time in your account settings. There is no long-term commitment, and you will not be charged for future months once the cancellation is processed.'} />
                </div>
            </div>
        </div>
    )
}

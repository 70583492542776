
const useColors=() => {
    const getColorToFilter=(color) => {
        switch(color) {
            case 'gray':
                return 'brightness(0.9)';
            case 'brown':
                return 'brightness(0.7) saturate(4000%) hue-rotate(200deg)';
            case 'orange':
                return 'brightness(0.9) saturate(5000%) hue-rotate(210deg)';
            case 'yellow':
                return 'brightness(0.95) saturate(6000%) hue-rotate(240deg)';
            case 'green':
                return 'brightness(0.9) saturate(5000%) hue-rotate(300deg)';
            case 'blue':
                return 'brightness(0.9) saturate(5000%) hue-rotate(60deg)';
            case 'purple':
                return 'brightness(0.8) saturate(5000%) hue-rotate(90deg)';
            case 'pink':
                return 'brightness(0.9) saturate(5000%) hue-rotate(120deg)';
            case 'red':
                return 'brightness(0.9) saturate(5000%) hue-rotate(180deg)';
            default:
                return '';
        }
    }

    // Return a hex code for a simple color according to the index of the user 
    const getColorOfUser=(uid='',ownUid='') => {
        let index=uid.charCodeAt(0)+uid.charCodeAt(1)+uid.charCodeAt(2)+uid.charCodeAt(3)+uid.charCodeAt(4)+uid.charCodeAt(5)+uid.charCodeAt(6)+uid.charCodeAt(7)
        index=index%10
        index=uid===ownUid? 0:index
        index=uid.length===0? 0:index
        switch(index) {
            case 0:
                return '#45ac71';
            case 1:
                return '#FF0000';
            case 2:
                return '#0000FF';
            case 3:
                return '#FFFF00';
            case 4:
                return '#FF00FF';
            case 5:
                return '#00FFFF';
            case 6:
                return '#FF8000';
            case 7:
                return '#FF0080';
            case 8:
                return '#80FF00';
            case 9:
                return '#80FF00';
            default:
                return '#FF0000';
        }
    }
    return {getColorOfUser,getColorToFilter}
}

export default useColors
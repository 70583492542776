import React,{useState} from 'react'
import Navbar from '../../components/Nav/Navbar'
import usePageTitle from '../../hooks/usePageTitle'
import HeroHeading from '../../components/Text/HeroHeading'
import Subheading from '../../components/Text/Subheading'
import SignInWithGoogle from '../../components/Buttons/SignInWithGoogle'
import ButtonPrimaryWithIcon from '../../components/Buttons/ButtonPrimaryWithIcon'
import {useNavigate} from 'react-router-dom'
import spiral from '../../assets/spiral.png'
import Text from '../../components/Text/Text'
import Icon from '../../components/Text/Icon'
import Card from '../../components/Cards/Card'
import Heading from '../../components/Text/Heading'
import FaqCard from '../../components/Cards/FaqCard'
import LandingResultCard from '../../components/Cards/LandingResultCard'
import MenuLink from '../../components/Links/Link'
import GuestBar from '../../components/Nav/GuestBar'
import heroCompletion from '../../assets/flowsage-completion.gif'
import heroReccomendation from '../../assets/flowsage-hero-recommendation.png'
import heroChat from '../../assets/flowsage-hero-chat.png'
import classNames from 'classnames'
import boxLogo from '../../assets/integrations/box-logo.png'
import docsLogo from '../../assets/integrations/docs-logo.png'
import youtubeLogo from '../../assets/integrations/youtube-logo.png'
import loomLogo from '../../assets/integrations/loom-logo.png'
import figmaLogo from '../../assets/integrations/figma-logo.png'
import driveLogo from '../../assets/integrations/drive-logo.webp'

export default function Landing() {
    const navigate=useNavigate()

    const [heroIndex,setHeroIndex]=useState(0)

    const [pageTitle,setPageTitle]=usePageTitle('Experience seamless idea visualization.')

    return (
        <>
            <div>
                <GuestBar />
                <div className="relative flex flex-col justify-center w-full gap-2 pt-5 bg-fixed bg-left-bottom bg-no-repeat bg-cover lg:gap-12 lg:p-20 align-center">
                    <HeroHeading className='z-10 w-full px-2 mx-auto text-center'>Effortlessly <span className='text-primary'>design</span>, <span className='text-primary'>share</span>, and <span className='text-primary'>optimize</span> flowcharts with the power of AI</HeroHeading>
                    <Subheading className="z-10 w-4/5 p-2 pl-0 mx-auto text-center lg:w-3/5 text-dark-200">
                        Experience seamless idea visualization. Navigate complexity and streamline idea pipelines.
                    </Subheading>
                    <div className='z-10 flex flex-col items-center justify-center w-1/2 gap-2 mx-auto mt-3 lg:flex-row'>
                        <SignInWithGoogle className='!flex !mt-0 text-center rounded-md !py-1.5' />
                        <ButtonPrimaryWithIcon onClick={() => navigate('/login')} icon="arrow-right-short" className="my-auto !py-1.5 !max-w-none">
                            Get started for free
                        </ButtonPrimaryWithIcon>
                    </div>
                    <img className='absolute z-0 w-48 opacity-70 bottom-24 left-1/2 lg:w-64 lg:bottom-20 lg:left-auto lg:-right-10 lg:opacity-100' alt='' src={spiral} />
                </div>
                <div className='w-full px-4 mx-auto mt-12 lg:mt-0 lg:w-3/4 lg:px-0'>
                    <div className='relative flex items-center justify-center w-full mx-auto'>
                        <img src={[heroCompletion,heroReccomendation,heroChat][heroIndex%3]} alt='' className={classNames('w-full transition-all duration-300')} />
                        <div className='absolute top-0 bottom-0 right-0 flex flex-col items-center justify-end bg-opacity-50 lg:w-1/3'>
                            <div className='flex items-center justify-end w-full gap-1 lg:gap-2'>
                                <Icon icon='chevron-left' onClick={() => setHeroIndex(heroIndex => heroIndex===0? 2:heroIndex-1)} className='!px-2 lg:!px-3 py-1 border rounded-full cursor-pointer text-md lg:py-2 lg:text-xl bg-light border-primary' />
                                <Icon icon='chevron-right' onClick={() => setHeroIndex(heroIndex => heroIndex+1)} className='!px-2 py-1 lg:!px-3 lg:py-2 text-md lg:text-xl border rounded-full cursor-pointer bg-light border-primary' />
                            </div>
                        </div>
                    </div>

                    <Heading className=' text-center w-full !text-lg lg:!text-xl'>
                        {['Save time with AI-Powered completions','Optimize flowcharts in seconds','Chat with your personal flowchart assistant'][heroIndex]}
                    </Heading>
                </div>
                <div className='w-full px-3 mx-auto mt-8 lg:mt-24'>
                    <Heading className='py-10 text-center'>Create flowcharts in <span className='text-primary'>half the time.</span></Heading>
                    <div className='grid items-center justify-center w-full grid-cols-1 gap-4 lg:grid-cols-3'>
                        <Card className='w-full gap-0.5 p-6 !border-0 !shadow-none lg:!shadow-none transition-transform'>
                            <Icon icon='clock-history' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>Create flowcharts in seconds</Subheading>
                            <Text className='pt-0'>Elevate your flowcharting experience with Flowsage's AI. Get intelligent auto-completions that anticipate your next move, making the creation process swift, intuitive, and uniquely tailored to your creative flow.</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 !border-0 !shadow-none lg:!shadow-none'>
                            <Icon icon='people' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>Collaborate Effortlessly</Subheading>
                            <Text className='pt-0'>Work together in real-time with Flowsage's collaborative features. Invite team members, provide instant feedback, and watch your ideas come to life with synchronized precision.</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 !border-0 !shadow-none lg:!shadow-none'>
                            <Icon icon='speedometer2' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>Optimize for Efficiency</Subheading>
                            <Text className='pt-0'>Let Flowsage analyze and optimize your flowcharts. Identify bottlenecks, streamline processes, and ensure your ideas are not just visualized but maximally efficient.</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 !border-0 !shadow-none lg:!shadow-none'>
                            <Icon icon='chat-left-dots' className='px-3 py-2 text-3xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>Access your Flowchart assistant</Subheading>
                            <Text className='pt-0'>Need assistance while charting your ideas? Flowsage's AI-driven chat support is here to help. Get instant guidance, suggestions, and tips to enhance your flowcharting experience.</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 !border-0 !shadow-none lg:!shadow-none'>
                            <Icon icon='files-alt' className='px-3 py-2 text-3xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>Manage Your Projects Seamlessly</Subheading>
                            <Text className='pt-0'>Use Flowsage as your central hub for project management. Organize tasks, timelines, and milestones effortlessly, keeping your projects on track and everyone on the same page.</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 !border-0 !shadow-none lg:!shadow-none'>
                            <Icon icon='phone' className='px-3 py-2 text-3xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>Unlimited flexibility</Subheading>
                            <Text className='pt-0'>Access your flowchart from anywhere, anytime. Flowsage's cloud-based platform ensures your ideas are never confined, allowing you to create, edit, and collaborate on-the-go.</Text>
                        </Card>
                    </div>
                </div>
                <div className='w-full px-3 mx-auto mt-8 lg:mt-24'>
                    <Subheading className='w-full font-normal text-center'>Integrates with:</Subheading>
                    <div className='grid grid-cols-3 gap-4 p-6 mx-auto mt-6 border shadow-md rounded-xl lg:w-1/2 place-items-center lg:grid-cols-6 border-primary bg-light'>
                        <img src={docsLogo} alt='Docs' className='!w-8' />
                        <img src={youtubeLogo} alt='Youtube' className='!w-12' />
                        <img src={driveLogo} alt='Drive' className='!w-12' />
                        <img src={loomLogo} alt='Loom' className='!w-10' />
                        <img src={figmaLogo} alt='Figma' className='!w-7' />
                        <img src={boxLogo} alt='Box' className='!w-12' />
                    </div>
                </div>
                <div className='w-full px-3 mx-auto mt-32'>
                    <Heading className='text-center'>Frequently Asked Questions</Heading>
                    <div className='mt-10'>
                        <FaqCard question='Is Flowsage suitable for beginners?' answer={
                            <Text>Flowsage is designed with user-friendly features, making it <span className='font-semibold'>accessible for beginners</span> while offering advanced functionalities for more experienced users. Our intuitive interface ensures that everyone can create and collaborate on flowcharts with ease.</Text>
                        } />
                        <FaqCard question='How much does Flowsage cost?' answer={
                            <>
                                <Text>Our complete library of tools is <span className='font-semibold'>free to use</span>. The Lite plan gives you 5 AI credits each month to use Flowsage. The Pro plan gives you unlimited access to all tools to <span className='font-semibold'>skyrocket your productivity</span>. </Text>
                                <Text className='mt-2'>See our <MenuLink to='/pricing'>Pricing</MenuLink> page for more details</Text></>
                        } />
                        <FaqCard question='Can I use Flowsage to collaborate with my team?' answer={
                            <>
                                <Text><span className='font-semibold'>Absolutely!</span> Flowsage offers seamless team collaboration. Invite team members, provide instant feedback, and work together in real-time. Our collaborative features ensure that your entire team is on the same page, enhancing communication and project coordination.</Text>
                            </>
                        } />
                        <FaqCard question="Is my data secure on the Flowsage Cloud?" answer={
                            <>
                                <Text>Yes, we take your data security seriously. Flowsage employs robust <span className='font-semibold'>security measures</span> to protect your information. Our cloud-based features are designed to provide flexibility without compromising the safety of your valuable data.</Text>
                            </>
                        } />
                        <FaqCard question="Can I access my flowcharts on different devices?" answer={
                            <>
                                <Text>Absolutely! Flowsage is built for <span className='font-semibold'>flexibility</span>. Access your flowcharts seamlessly from different devices. However, please note that on mobile devices, Flowsage currently supports a read-only view. You can review your flowcharts and collaborate on them using other devices. Create, edit, and collaborate on your ideas anytime, anywhere with full functionality on non-mobile devices.</Text>
                            </>
                        } />
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8 px-3 mx-auto mt-32 text-center'>
                    <HeroHeading className='!text-2xl lg:!text-4xl'>Be part of our <span className='transition-colors text-primary hover:text-primary-600'>goal</span>!</HeroHeading>
                    <ButtonPrimaryWithIcon onClick={() => navigate('/login')} icon="arrow-right-short" className="m-auto lg:hidden">
                        Get started for free
                    </ButtonPrimaryWithIcon>
                    <Subheading className='w-full pt-0 mx-auto lg:w-1/2'>We want to help 10,000 professionals gain back their time by the end of the year.</Subheading>
                    <ButtonPrimaryWithIcon onClick={() => navigate('/login')} icon="arrow-right-short" className="hidden m-auto lg:flex !py-1.5 lg:!w-64">
                        Get started for free
                    </ButtonPrimaryWithIcon>
                </div>
            </div>
        </>
    )
}

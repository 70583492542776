/** @format */

import React,{useContext,useEffect,useState,useRef} from 'react'
import {Outlet,useNavigate} from 'react-router-dom'
import Footer from '../components/Nav/Footer'
import UserContext from '../contexts/UserContext'
import {setAnalyticsCollectionEnabled,setUserId} from 'firebase/analytics'
import analytics from '../helpers/analytics'
import Loading from '../pages/Loading'
import Consent from '../components/Nav/Consent'
import useAnalyticsConsent from '../hooks/useAnalyticsConsent'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

export default function Guest({children,accessible=false,footer=accessible,fullWidth=false,...props}) {
  let navigate=useNavigate()
  const [user,loading]=useContext(UserContext)
  const [consent,setConsent]=useState(false)
  const [tawkLoaded,setTawkLoaded]=useState()
  const tawkMessengerRef=useRef(null)

  useEffect(() => {
    if(tawkLoaded) {
      console.log(tawkMessengerRef.current.setAttributes)
      if(!loading&&user) {
        tawkMessengerRef.current.setAttributes({
          name: user.displayName,
          email: user.email,
          id: user.uid
        })
      } else {
        tawkMessengerRef.current?.setAttributes({
          name: 'Guest',
          email: '',
          id: ''
        })
      }
    }
  },[loading,user,tawkLoaded])

  useEffect(() => {
    if(!accessible) {
      if(user&&!loading) {
        navigate('/dashboard')
      }
    }
    if(user) {
      setUserId(analytics,user.uid)
    }

  },[user,navigate,accessible,loading])

  if(loading) {
    return <Loading />
  }

  return (
    <div className={`min-h-full px-4 pb-10 mx-auto font-enwrite ${fullWidth? 'w-full':'max-w-7xl'}`}>
      <Outlet />
      <TawkMessengerReact propertyId='66ba0b45146b7af4a4395f33' onLoad={() => setTawkLoaded(true)} widgetId='1i53bo206' ref={tawkMessengerRef} />
      {footer? <Footer />:null}
    </div>
  )
}

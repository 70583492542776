import React,{useCallback,useContext,useState,useEffect,useRef,useMemo} from 'react'
import {Outlet,useNavigate,useParams} from 'react-router-dom'
import {ReactFlowProvider,ReactFlow,Background,Controls,MiniMap,BackgroundVariant,ConnectionMode,MarkerType,Panel} from 'reactflow'
import 'reactflow/dist/style.css';
import Navbar from '../../components/Nav/Navbar'
import {CreditsProvider} from '../../contexts/CreditsContext'
import {ProjectsProvider} from '../../contexts/ProjectsContext'
import {getCredits} from '../../helpers/functions'
import UserContext from '../../contexts/UserContext'
import ErrorContext from '../../contexts/ErrorContext'
import {getDocs,collection,orderBy} from 'firebase/firestore'
import {db,rt,storage} from '../../helpers/firebase'
import {getDownloadURL,ref} from 'firebase/storage'
import FeedbackCard from '../../components/Cards/FeedbackCard'
import {ref as rtRef,get,query,equalTo,orderByChild,child,onValue,push} from 'firebase/database'
import {NodeProvider} from '../../contexts/NodeContext'
import TextNode from '../../components/Nodes/TextNode'
import RectNode from '../../components/Nodes/RectNode'
import HorCylinderNode from '../../components/Nodes/HorCylinderNode'
import RefTrapNode from '../../components/Nodes/RefTrapNode'
import TrapNode from '../../components/Nodes/TrapNode'
import ArrowRectNode from '../../components/Nodes/ArrowRectNode'
import PentNode from '../../components/Nodes/PentNode'
import SquircleNode from '../../components/Nodes/SquircleNode'
import CylinderNode from '../../components/Nodes/CylinderNode'
import CloudNode from '../../components/Nodes/CloudNode'
import CrossNode from '../../components/Nodes/CrossNode'
import DiamondRectNode from '../../components/Nodes/DiamondRectNode'
import SkewRectNode from '../../components/Nodes/SkewRectNode'
import CircleNode from '../../components/Nodes/CircleNode'
import ArrowNode from '../../components/Nodes/ArrowNode'
import RoundedNode from '../../components/Nodes/RoundedNode'
import TriangleNode from '../../components/Nodes/TriangleNode'
import HexNode from '../../components/Nodes/HexNode'
import DNode from '../../components/Nodes/DNode'
import TapeNode from '../../components/Nodes/TapeNode'
import DiamondNode from '../../components/Nodes/DiamondNode'
import ReccomendationNode from '../../components/Nodes/ReccomendationNode'
import EmbedNode from '../../components/Nodes/EmbedNode'
import YoutubeNode from '../../components/Nodes/Integrations/YoutubeNode'
import FigmaNode from '../../components/Nodes/Integrations/FigmaNode'
import LoomNode from '../../components/Nodes/Integrations/LoomNode'
import BoxNode from '../../components/Nodes/Integrations/BoxNode'
import GoogleDriveNode from '../../components/Nodes/Integrations/GoogleDriveNode'
import GoogleDocsNode from '../../components/Nodes/Integrations/GoogleDocsNode'
import GoogleSheetsNode from '../../components/Nodes/Integrations/GoogleSheetsNode'
import GoogleSlidesNode from '../../components/Nodes/Integrations/GoogleSlidesNode'
import Loading from '../Loading'
import Subheading from '../../components/Text/Subheading';
import icon from '../../assets/icon_light.svg'

function EmbedWithoutProvider({uid,projectId}) {
    const navigate=useNavigate()
    const setError=useContext(ErrorContext)
    const [nodes,setNodes]=useState([])
    const [edges,setEdges]=useState([])
    const ReactFlowRef=useRef(null)
    const [gridScale,setGridScale]=useState(10)
    const [reactFlowInstance,setReactFlowInstance]=useState(null);
    const [projectLoading,setProjectLoading]=useState(true)
    const [title,setTitle]=useState('Untitled Flowchart')

    useEffect(() => {
        if(!projectId) {
            navigate('/')
        }

        const projectRef=rtRef(rt,`projects/${uid}/${projectId}`)
        onValue(projectRef,(snapshot) => {
            if(!snapshot.exists()) {
                navigate('/')
                return
            }
            const project=snapshot.val()
            setProjectLoading(false)
            if(project) {
                setNodes(prevNodes => (project.nodes||[]).map((node) => {
                    return {
                        ...node,
                        selected: prevNodes.find((n) => n.id===node.id)?.selected||false
                    }
                }))
                setEdges(prevEdges => (project.edges||[]).map((edge) => {
                    return {
                        ...edge,
                        selected: prevEdges.find((e) => e.id===edge.id)?.selected||false
                    }
                }))
                setTitle(project.title||'Untitled Flowchart')
                setGridScale(project.gridScale||10)
            }
        })
    },[projectId,navigate,uid,setNodes,setEdges])


    const [loading,setLoading]=useState(true)

    const setNodeValue=() => {return }
    const getNodeValue=(id,value='text') => {
        const node=nodes.find((node) => node.id===id);
        if(!node||!node.data) {
            return null
        }
        return typeof node?.data[value]==='undefined'? null:node?.data[value];
    }
    const deleteNode=() => {return }

    const nodeTypes=useMemo(() => ({
        textNode: TextNode,
        rectNode: RectNode,
        horCylinderNode: HorCylinderNode,
        refTrapNode: RefTrapNode,
        trapNode: TrapNode,
        arrowRectNode: ArrowRectNode,
        pentNode: PentNode,
        squircleNode: SquircleNode,
        cylinderNode: CylinderNode,
        cloudNode: CloudNode,
        crossNode: CrossNode,
        diamondRectNode: DiamondRectNode,
        skewRectNode: SkewRectNode,
        circleNode: CircleNode,
        arrowNode: ArrowNode,
        roundedNode: RoundedNode,
        triangleNode: TriangleNode,
        hexNode: HexNode,
        dNode: DNode,
        tapeNode: TapeNode,
        diamondNode: DiamondNode,
        reccNode: ReccomendationNode,
        embedNode: EmbedNode,
        youtubeNode: YoutubeNode,
        figmaNode: FigmaNode,
        loomNode: LoomNode,
        boxNode: BoxNode,
        googleDriveNode: GoogleDriveNode,
        googleDocsNode: GoogleDocsNode,
        googleSheetsNode: GoogleSheetsNode,
        googleSlidesNode: GoogleSlidesNode
    }),[]);

    const proOptions={
        hideAttribution: true,
    }

    if(projectLoading) {
        return <Loading />
    }

    return (
        <div className={`w-full h-screen`} >
            <NodeProvider value={[setNodeValue,getNodeValue,deleteNode]}>
                <ReactFlow ref={ReactFlowRef} onInit={(reactflow) => {setReactFlowInstance(reactflow)}} nodeTypes={nodeTypes} proOptions={proOptions} fitView defaultViewport={{x: 0,y: 0,zoom: 1.5}} nodes={nodes} edges={edges} snapGrid={[gridScale,gridScale]} snapToGrid={gridScale>0} selectionOnDrag={true} elementsSelectable={false} nodesDraggable={false} nodesConnectable={false} edgesUpdatable={false} connectionMode={ConnectionMode.Loose} nodesFocusable={false} defaultEdgeOptions={{
                    markerStart: {
                        type: MarkerType.ArrowClosed,color: 'rgba(0,0,0,0)'
                    },markerEnd: {
                        type: MarkerType.ArrowClosed,color: 'rgba(0,0,0,0)'
                    }
                }}>
                    <Background variant={BackgroundVariant.Dots} gap={10} />
                    <Controls showInteractive={false} />
                    <Panel position='top-right' style={{zIndex: 1000}}>
                        <Subheading>{title}</Subheading>
                    </Panel>
                    <Panel position='top-left' style={{zIndex: 1000}}>
                        <div className='flex items-center justify-center cursor-pointer' onClick={() => window.top.location.href='https://www.flowsage.co'}>
                            <img src={icon} alt='Flowsage' className='w-10 h-10' />
                            <Subheading>Flowsage</Subheading>
                        </div>
                    </Panel>
                    <MiniMap nodeStrokeWidth={3} zoomable pannable />
                </ReactFlow>
            </NodeProvider>
        </div>
    )
}

export default function Embed() {

    const {uid,projectId}=useParams()

    return (<div className='w-full min-h-screen'>
        <ReactFlowProvider>
            <EmbedWithoutProvider uid={uid} projectId={projectId} />
        </ReactFlowProvider>
    </div>)
}

import React from 'react'
import usePageTitle from '../../hooks/usePageTitle'

export default function Experience() {
    const [pageTitle,setPageTitle]=usePageTitle('User Experience Survey')

    return (
        <div className='w-full min-h-screen'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdMPLbQJjFMU1yTYys13jhfURKW4NenhvN0pII_Fn287Zgh1Q/viewform?embedded=true" className='w-full min-h-screen' frameborder="0" title='User Experience Survey' marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}

import React from 'react'
import Icon from '../../components/Text/Icon'
import Text from '../../components/Text/Text'
import Subheading from '../../components/Text/Subheading'
import Link from '../../components/Links/Link'
import usePageTitle from '../../hooks/usePageTitle'
import useSEO from '../../hooks/useSEO'

export default function NotFound() {
    const [pageTitle,setPageTitle]=usePageTitle('404')
    useSEO({indexable: false})

    return (
        <div className='flex flex-col items-center justify-center w-full h-screen'>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5'>
                <Icon className='m-0 text-5xl text-primary-200 lg:text-9xl animate-spin ' icon='bounding-box-circles' />
                <Subheading>Maybe you're a bit lost</Subheading>
                <Text className='w-2/3 px-4 text-center lg:w-1/2 xl:w-1/4'>You probably came here for the balloons and confetti. They have been moved to the <Link to='/'>homepage</Link>.</Text>
            </div>
        </div>
    )
}

/** @format */

import React,{useState,useContext,useEffect} from 'react'
import {useNavigate,useParams} from 'react-router-dom'
import ButtonPrimaryWithIcon from '../Buttons/ButtonPrimaryWithIcon'
import MenuLink from '../Links/MenuLink'
import icon from '../../assets/icon_light.svg'
import {Link} from 'react-router-dom'
import Subheading from '../Text/Subheading'
import UserContext from '../../contexts/UserContext'
import {getDoc,doc} from 'firebase/firestore'
import {db,rt} from '../../helpers/firebase'
import InputHeading from '../Inputs/InputHeading'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import {getDownloadURL,ref} from 'firebase/storage'
import {storage} from '../../helpers/firebase'
import useUserSubscription from '../../hooks/useUserSubscription'
import CreditsContext from '../../contexts/CreditsContext'
import ButtonSecondary from '../Buttons/ButtonSecondary'
import Icon from '../Text/Icon'
import ProjectsContext from '../../contexts/ProjectsContext'
import {child,get,onValue,push,remove,ref as rtRef,set,update} from 'firebase/database'
import ButtonPrimary from '../Buttons/ButtonPrimary'
import Card from '../Cards/Card'
import Input from '../Inputs/Input'
import Text from '../Text/Text'
import useValidator from '../../hooks/useValidator'
import ErrorContext from '../../contexts/ErrorContext'
import {toPng} from 'html-to-image'
import {useReactFlow,getRectOfNodes,getTransformForBounds} from 'reactflow'
import {logEvent} from 'firebase/analytics'
import analytics from '../../helpers/analytics'


function downloadImage(dataUrl,filename='flowchart') {
  const a=document.createElement('a');

  a.setAttribute('download',`${filename}.png`);
  a.setAttribute('href',dataUrl);
  a.click();
}

function Navbar({...props},ref) {
  const {projectId,uid}=useParams()
  const navigate=useNavigate()
  const [projectTitle,setProjectTitle]=useState('')
  const [user]=useContext(UserContext)
  const [linkCopied,setLinkCopied]=useState(false)
  const [embedCopied,setEmbedCopied]=useState(false)
  const [embedLinkCopied,setEmbedLinkCopied]=useState(false)
  const {getNodes}=useReactFlow();
  const [userPlan,setUserPlan]=useState('free')
  const {getUserPlan}=useUserSubscription()
  const [collaboratorEmail,setCollaboratorEmail]=useState('')
  const [collaborators,setCollaborators]=useState([])
  const [showInviteCollaborator,setShowInviteCollaborator]=useState(false)
  const [showShare,setShowShare]=useState(false)
  const [collaboratorEmailError,setCollaboratorEmailError]=useState(false)
  const [credits,updateCredits]=useContext(CreditsContext)
  const [projects,updateProjects]=useState(ProjectsContext)
  const [photoUrl,setPhotoUrl]=useState('https://firebasestorage.googleapis.com/v0/b/flowsage-web.appspot.com/o/default-user.jpg?alt=media&token=e6566b13-e279-4a9f-9171-6232dafaa174')
  const validator=useValidator()
  const setError=useContext(ErrorContext)

  useEffect(() => {
    getUserPlan().then((plan) => {
      setUserPlan(plan)
    })
    updateCredits()
  },[user?.uid,updateCredits,getUserPlan])

  useEffect(() => {
    if(linkCopied) {
      setTimeout(() => {
        setLinkCopied(false)
      },1500)
    }
  },[linkCopied])

  useEffect(() => {
    if(embedCopied) {
      setTimeout(() => {
        setEmbedCopied(false)
      },1500)
    }
  },[embedCopied])

  useEffect(() => {
    if(embedLinkCopied) {
      setTimeout(() => {
        setEmbedLinkCopied(false)
      },1500)
    }
  },[embedLinkCopied])

  useEffect(() => {
    if(projectId&&!uid) {
      const projectRef=rtRef(rt,'projects/'+user.uid+'/'+projectId)
      get(projectRef).then(snapshot => {
        if(snapshot.exists()) {
          const project=snapshot.val()
          setProjectTitle(project.title)
        }
      })
      onValue(child(projectRef,'collaborators'),snapshot => {
        const collaborators=snapshot.val()||{}
        console.log(collaborators)
        setCollaborators(Object.keys(collaborators||{}).map(collaborator => decodeURIComponent(collaborator))||[])
      })
    }
  },[projectId,user?.uid,uid])

  function handleChangeTitle() {
    if(projectId) {
      const projectRef=rtRef(rt,'projects/'+user.uid+'/'+projectId)
      update(projectRef,{title: projectTitle})
    }
  }

  function makeProjectPublic() {
    const projectRef=rtRef(rt,'projects/'+user.uid+'/'+projectId)
    update(projectRef,{public: true})
  }

  function addCollaborator(e) {
    e.preventDefault()
    if(validator.email(collaboratorEmail)[0]) {
      if(collaborators.includes(collaboratorEmail)) {
        setCollaboratorEmailError(true)
        document.addEventListener('keyup',() => {
          setCollaboratorEmailError(false)
        },{once: true})
        return
      }

      if(userPlan==='free'&&collaborators.length>=1) {
        setCollaboratorEmailError(true)
        setError('You have reached the maximum number of collaborators for your plan')
        return
      }
      if(userPlan==='plus'&&collaborators.length>=5) {
        setCollaboratorEmailError(true)
        setError('You have reached the maximum number of collaborators for your plan')
        return
      }

      const projectRef=rtRef(rt,'projects/'+user.uid+'/'+projectId)
      update(child(projectRef,'collaborators/'+encodeURIComponent(collaboratorEmail).replace(/\./g,'%2E')),{role: 'editor'})
      setCollaboratorEmail('')
    } else {
      setCollaboratorEmailError(true)
      document.addEventListener('keyup',() => {
        setCollaboratorEmailError(false)
      },{once: true})
    }
  }

  function removeCollaborator(collaborator) {
    const projectRef=rtRef(rt,'projects/'+user.uid+'/'+projectId)
    remove(child(child(projectRef,'collaborators'),encodeURIComponent(collaborator).replace(/\./g,'%2E')))
  }

  function exportFlowchart() {
    const nodesBounds=getRectOfNodes(getNodes());
    let transform=getTransformForBounds(nodesBounds,1000,1000,0.5,2);
    logEvent(analytics,'export_flowchart')

    toPng(document.querySelector('.react-flow__viewport'),{
      backgroundColor: 'rgba(0,0,0,0)',
      width: 1000,
      height: 1000,
      style: {
        width: 1000,
        height: 1000,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then((data) => downloadImage(data,projectTitle.toLowerCase().replace(/ /g,'_')))
  }

  return (
    <nav className="box-border flex items-center justify-between w-full p-4 border-b" ref={ref} {...props}>
      <div className="items-center justify-start hidden w-full my-auto md:flex">
        <Link className='flex-1 max-w-3tiny' to='/dashboard'>
          <img src={icon} alt="logo" className="p-0 max-w-3tiny" />
        </Link>
        {(projectId&&!uid)&&<form onSubmit={(e) => {e.preventDefault(); handleChangeTitle(); e.target.firstChild.blur()}}><InputHeading onBlur={handleChangeTitle} value={projectTitle} setValue={setProjectTitle} /></form>}
      </div>
      <div className="w-1/5 my-auto md:hidden max-w-3tiny">
        <Link to='/dashboard'>
          <img src={icon} alt="logo" />
        </Link>
      </div>
      <div className='flex items-center justify-center gap-2'>
        {(projectId&&!uid)&&<div className='relative'><ButtonSecondary onClick={() => setShowShare(showShare => !showShare)} className='hidden w-32 border border-dashed shadow-none text-primary-700 md:block lg:w-32 bg-light hover:bg-primary-50 border-primary lg:shadow-none hover:shadow-none'>Share <Icon className={'inline !px-1'} icon='upload' /></ButtonSecondary>{showShare&&<Card className='absolute !w-[200%] lg:!w-[400%] !top-[150%] h-min right-0 z-50 !border'>
          <Subheading className='px-0 text-center'>Share your project</Subheading>
          <Card onClick={() => {setLinkCopied(true); makeProjectPublic(); navigator.clipboard.writeText('https://www.flowsage.co/dashboard/design/public/'+user?.uid+'/'+projectId)}} clickable className='flex !flex-row lg:!flex-row items-center justify-start gap-2 p-2 mt-4 w-full' >
            <Icon className='text-primary !px-1 lg:!px-1 text-2xl' icon={linkCopied? 'check-lg':'link-45deg'} />
            <Text className='text-primary'>Copy Link</Text>
          </Card>
          <Card onClick={() => {exportFlowchart()}} clickable className='flex !flex-row lg:!flex-row items-center justify-start gap-2 p-2 mt-2 w-full' >
            <Icon className='text-primary !px-1 lg:!px-1 text-2xl' icon='download' />
            <Text className='text-primary'>Export as PNG</Text>
          </Card>
          <Card onClick={() => {makeProjectPublic(); window.location.href=`mailto:?subject=${projectTitle}%20%7C%20Flowsage&body=${'https://www.flowsage.co/dashboard/design/public/'+user?.uid+'/'+projectId}`}} clickable className='flex !flex-row lg:!flex-row items-center justify-start gap-2 p-2 mt-2 w-full' >
            <Icon className='text-primary !px-1 lg:!px-1 text-2xl' icon='envelope' />
            <Text className='text-primary'>Email</Text>
          </Card>
          <div className='flex items-center justify-between w-full gap-2'>
            <Card onClick={() => {
              setEmbedCopied(true); makeProjectPublic(); navigator.clipboard.writeText(
                `<iframe src="https://www.flowsage.co/embed/${user?.uid}/${projectId}" width="100%" height="100%" style="border: none;"></iframe>`
              )
            }} clickable className='flex !flex-row lg:!flex-row items-center justify-start gap-2 p-2 mt-2 w-full' >
              <Icon className='text-primary !px-1 lg:!px-1 text-2xl' icon={embedCopied? 'check-lg':'code'} />
              <Text className='text-primary'>Copy Embed Code</Text>
            </Card>
            <Card onClick={() => {
              setEmbedLinkCopied(true); makeProjectPublic(); navigator.clipboard.writeText(
                `https://www.flowsage.co/embed/${user?.uid}/${projectId}`
              )
            }} clickable className='flex !flex-row lg:!flex-row items-center justify-start gap-2 p-2 mt-2 w-full' >
              <Icon className='text-primary !px-1 lg:!px-1 text-2xl' icon={embedLinkCopied? 'check-lg':'link-45deg'} />
              <Text className='text-primary'>Copy Embed Link</Text>
            </Card>
          </div>
          <Text className='mt-4 !leading-tight text-left text-zinc-400 text-md lg:text-sm'>Anyone with this link can view the project. If you want to allow editing, add them as a collaborator.</Text>
        </Card>}</div>}
        {(projectId&&!uid)&&<div className='relative'><ButtonPrimary onClick={() => {
          setShowInviteCollaborator(showInviteCollaborator => !showInviteCollaborator)
        }} className='hidden w-32 border border-dashed shadow-none md:block lg:w-32 hover:bg-primary-50 border-primary lg:shadow-none hover:shadow-none'>Invite</ButtonPrimary>
          {showInviteCollaborator&&<Card className='absolute !w-[200%] lg:!w-[400%] !top-[150%] h-min right-0 z-50 !border'>
            <Subheading className='px-0 text-center'>Invite Collaborator</Subheading>
            <form onSubmit={addCollaborator} className='flex items-center justify-center !w-full lg:w-full gap-2'>
              <Input containerClassName='!w-full !flex-1 px-0' value={collaboratorEmail} hasValidationError={collaboratorEmailError} setValue={setCollaboratorEmail} className='lg:!w-full !w-full !flex-1' placeholder='Email' />
              <ButtonPrimary type='submit' className='!w-auto lg:!w-auto px-4 !flex-0'>Send</ButtonPrimary>
            </form>
            {collaborators.length>0&&<><Subheading className='px-0 pt-8 pb-4 text-center'>Collaborators</Subheading>
              <div className='flex flex-col gap-4 !w-full lg:!w-full'>
                {collaborators.map(collaborator => <div key={collaborator} className='flex items-center justify-between'>
                  <Text className='font-semibold'>{collaborator}</Text>
                  <ButtonSecondary onClick={() => removeCollaborator(collaborator)} className='!w-auto px-4 !flex-0'>Remove</ButtonSecondary>
                </div>)}
              </div></>}
          </Card>}</div>}
        <div className="relative justify-center block align-center">
          <img alt='Profile' onClick={() => navigate('/dashboard/settings')} src={user?.photoURL||'https://firebasestorage.googleapis.com/v0/b/flowsage-web.appspot.com/o/default-user.jpg?alt=media&token=e6566b13-e279-4a9f-9171-6232dafaa174'} className='object-cover p-2 rounded-full cursor-pointer max-w-3tiny aspect-square ms-clarity-hidden' />
          {userPlan==='premium'&&<div className='absolute px-2 py-0.5 text-xs rounded-full -bottom-0 -right-0 border border-primary bg-primary-100 text-primary'>Pro</div>}
          {userPlan==='plus'&&<div className='absolute px-2 py-0.5 text-xs rounded-full -bottom-0 -right-0 border border-primary bg-primary-100 text-primary'>Plus</div>}
        </div>
      </div>
    </nav>
  )
}

export default React.forwardRef(Navbar)
import React,{useCallback,useContext,useEffect,useState} from 'react';
import Input from '../../components/Inputs/Input';
import Text from '../../components/Text/Text';
import Logobar from '../../components/Nav/Logobar';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import {
    getAuth,
    signInWithEmailLink,
    isSignInWithEmailLink,
    setPersistence,
    browserLocalPersistence,
} from 'firebase/auth';
import Heading from '../../components/Text/Heading';
import {useNavigate} from 'react-router-dom';
import usePageTitle from '../../hooks/usePageTitle';
import ErrorContext from '../../contexts/ErrorContext';
import useSEO from '../../hooks/useSEO';
import useValidator from '../../hooks/useValidator';
import {logEvent} from 'firebase/analytics';
import analytics from '../../helpers/analytics';
import {useParams} from 'react-router-dom';

export default function Verify() {
    usePageTitle('Login');
    useSEO({indexable: false});

    const validator=useValidator();

    const setError=useContext(ErrorContext);

    const {redirect}=useParams();

    let navigate=useNavigate();
    const [loginEmail,setLoginEmail]=useState('');
    const [loginWithEmailError,setLoginWithEmailError]=useState(false);
    const [verifyLoading,setVerifyLoading]=useState(false);
    const [loginWithEmailInstructions,setLoginWithEmailInstructions]=useState([
        'You will be sent a magic link to login directly from your email',
        'dark',
    ]);

    useEffect(() => {
        setLoginWithEmailInstructions([
            'To confirm your identity, please enter the email you logged in with.',
            'dark',
        ]);
    },[loginWithEmailError]);

    const handleLogin=useCallback(() => {
        if(verifyLoading) {
            return;
        }
        const auth=getAuth();
        if(isSignInWithEmailLink(auth,window.location.href)) {
            let email=window.localStorage.getItem('emailForSignIn');
            if(!email&&!loginEmail) {
                return;
            }
            setPersistence(auth,browserLocalPersistence)
                .then(() => {
                    if(email||validator.email(loginEmail)) {
                        setVerifyLoading(true);
                        signInWithEmailLink(auth,email||loginEmail,window.location.href)
                            .then((result) => {
                                logEvent(analytics,'loginWithEmail');
                                window.localStorage.removeItem('emailForSignIn');
                                const isNewUser=
                                    result.user.metadata.creationTime===
                                    result.user.metadata.lastSignInTime||
                                    !result.user.metadata.lastSignInTime;
                                navigate(
                                    decodeURIComponent(redirect||'')||
                                    (isNewUser? '/pricing':'/dashboard')
                                );
                            })
                            .catch((error) => {
                                console.error(error);
                                setVerifyLoading(false);
                                if(error.code==='auth/email-already-in-use') {
                                    return;
                                }
                                if(error.code==='auth/invalid-email') {
                                    setError('Please enter a valid email address.');
                                    setLoginWithEmailError(true);
                                }
                                if(error.code==='auth/user-disabled') {
                                    setError('Your account has been disabled.');
                                    setLoginWithEmailError(true);
                                }
                                if(error.code==='auth/user-not-found') {
                                    setError('No account found with that email address.');
                                    setLoginWithEmailError(true);
                                }
                                if(error.code==='auth/invalid-action-code') {
                                    setError(
                                        'The link you clicked is invalid. Please login again.'
                                    );
                                    setLoginWithEmailError(true);
                                }
                                setLoginWithEmailError(true);
                                setError('Something went wrong, please try again!');
                            });
                    } else {
                        setError('Please enter a valid email address.');
                        setLoginWithEmailError(true);
                        document.addEventListener(
                            'keyup',
                            () => {
                                setError('');
                                setLoginWithEmailError(false);
                            },
                            {once: true}
                        );
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setLoginWithEmailError(true);
                    setError('Something went wrong, please try again!');
                });
        }
    },[loginEmail,validator,navigate,setError,redirect,verifyLoading]);

    useEffect(() => {
        handleLogin();
    },[]);

    return (
        <>
            <div>
                <Logobar />
                <div className="flex flex-col items-center justify-center w-full gap-20 py-10 m-2">
                    <div>
                        <Heading className="p-0 text-2xl text-left w-80 lg:w-128 lg:text-3xl">
                            Email confirmation
                        </Heading>
                        <Text
                            className={
                                'w-80 lg:w-128 text-left'+
                                ' '+
                                `text-${loginWithEmailInstructions[1]}`
                            }>
                            {loginWithEmailInstructions[0]}
                        </Text>
                    </div>
                    <div>
                        <Input
                            className={loginWithEmailError? ' !border-danger':''}
                            value={loginEmail}
                            setValue={setLoginEmail}
                            disabled={verifyLoading}
                            label={false}
                            type="email"
                            placeholder="name@company.co"
                        />
                        <ButtonPrimary
                            className="mt-4 mx-auto lg:!w-128"
                            onClick={handleLogin}>
                            {verifyLoading? 'Verifying...':'Verify'}
                        </ButtonPrimary>
                    </div>
                </div>
            </div>
        </>
    );
}
